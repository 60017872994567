/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// COMPONENTS
import _ from "underscore";
import CheckoutCartButton from "../../../components/CheckoutCartButton/CheckoutCartButton";
import QuickCheckoutButton from "../../../components/QuickCheckoutButton/QuickCheckoutButton";
// Redux
import * as userActions from "../../../redux/actions/user";
import * as elementActions from "../../../redux/actions/elements";
import styles from "./SingleProduct.module.css";
import DeliveryPickUpWidget from "../../../components/DeliveryPickUpWidget/DeliveryPickUpWidget";
import Loader from "../../../components/Loader/Loader";

import { orderTypeIds, orderMethods } from "../../../shared/constants/order";
import { shippingMethods, storeIds } from "../../../shared/constants/store";
import endpoints from "../../../shared/constants/endpoints";
import { historyAction } from "../../../shared/constants/history";
import * as orderRepo from "../../../shared/repos/graphql/order";
import * as productConstants from "../../../shared/constants/product";
import * as productRepo from "../../../shared/repos/graphql/product";

import * as tagmanagerEvents from "../../../shared/utilities/tagmanagerEvents";
import {
  getErrorMessages,
  getErrorReasons,
  routeCountryPath
} from "../../../shared/utilities/common";
import errorCodes from "../../../shared/constants/errorCodes";
import { handleProductWithDirectLink } from "../../../shared/utilities/product";
import {
  isDeliveryOrPickup,
  clearCart
} from "../../../shared/utilities/orders";
import { defaultCurrency } from "../../../shared/constants/currency";

import { openOrderUpdateFailModal } from "../../../shared/utilities/modals";
import * as loyaltyAction from "../../../redux/actions/loyalty";
import { store } from "../../../redux/store";
import { calculateLoyaltyPoints } from "../../../shared/utilities/loyalty";
import { PUNCHH_TYPE } from "../../../shared/constants/loyaltyType";
import { renderLoyaltyPrice } from "../../../shared/utilities/renderings";

const addIcon = require("../imgs/plus.svg");
const minusIcon = require("../imgs/minus.svg");
const darkAddIcon = require("../imgs/dark-plus.svg");
const darkMinusIcon = require("../imgs/dark-minus.svg");
const backIcon = require("../imgs/back-icon.svg");
const lightBackIcon = require("../imgs/light-back-icon.svg");

class SingleProduct extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    this.state = {
      productPrice: 0.0,
      quantity: null,
      updateCartLoading: false,
      updatePriceLoading: false,
      productAddError: "",
      locationKey: history.location.key,
      points: 0
    };
  }

  componentDidMount() {
    this.setProductData()
      .then(() => {
        handleProductWithDirectLink(this);
        // eslint-disable-next-line no-unused-vars
      })
      .catch(() => {});
  }

  async componentDidUpdate(prevProps) {
    const { selectedStore, history, location } = this.props;
    const { locationKey } = this.state;

    if (selectedStore && selectedStore !== prevProps.selectedStore) {
      await this.setProductData();
    }
    if (history.action === historyAction.pop && location.key !== locationKey) {
      history.goBack();
    }
  }

  setProductData = async () => {
    const { match, userCart, loyalty, redeemables, isShowOosProducts } = this.props;
    const { quantity } = this.state;

    const { params } = match;
    const { id, poid } = params;
    const selectedItem = userCart.find(item => item.id === poid);
    this.setState({
      updatePriceLoading: true,
      updateCartLoading: true,
      item: selectedItem
    });
    const redeemableId = redeemables?.redeemableId ? redeemables?.redeemableId : null;
    const { data } = await productRepo.getProduct(id, redeemableId, isShowOosProducts);
    const { product } = data;
    if (!quantity) {
      this.setState({
        quantity: selectedItem ? selectedItem.quantity : 1
      });
    }

    this.setState({
      product,
      productPrice: product.price,
      updatePriceLoading: false,
      updateCartLoading: false
    });

    if (loyalty) {
      this.getProductLoyaltyPoints(loyalty, id, poid);
    }
  };

  getProductLoyaltyPoints = (loyalty, id, poid) => {
    const {order, loyaltyPoints, loyaltyDeals, loyaltyRedeemables, history} = this.props;
    const loyaltyProducts = loyalty === PUNCHH_TYPE.REDEEMABLE ? loyaltyRedeemables : loyaltyDeals
    try {
      const points = calculateLoyaltyPoints(
        loyalty,
        id,
        poid,
        loyaltyPoints,
        loyaltyProducts,
        order
      );
      this.setState({
        points
      })
    } catch (error) {
      history.push(endpoints.loyalty);
    }
  }

  /**
   * Handles quantity state setting
   * @param {boolean} increase - boolean argument to handle increase/decrease
   */
  handleQuantityChange = increase => {
    const { quantity } = this.state;
    // if decreasing and quantity is at 0, do nothing more
    if (!increase && quantity === 1) return;
    // otherwise increase or decrease state
    this.setState({ quantity: increase ? quantity + 1 : quantity - 1 });
  };

  handleProductUpdate = async () => {
    const {
      userCartId,
      history,
      setUserCart,
      loyalty,
      redeemables
    } = this.props;
    const { item, product, quantity } = this.state;

    tagmanagerEvents.addToCart({
      ...product,
      quantity,
      price: (product.price * quantity).toFixed(2)
    });

    const res = await orderRepo.addUpdateOrderProduct(userCartId, {
      orderProductId: parseInt(item.id, 10),
      product: product.id,
      quantity,
      redeemableId: loyalty === "redeem" ? redeemables?.redeemableId : null,
      dealId: loyalty === "deals" ? redeemables?.redeemableId : null
    });

    const order = res.data.updateProductOrder;

    const localStorageCart = order.items.map(item1 => item1);
    setUserCart(localStorageCart);

    history.push(routeCountryPath(`/checkout/view-order/`));
  };

  handleAddItemToOrder = async (orderCode, quickCheckout = false) => {
    const {
      setUserCart,
      history,
      userOrderMethod,
      loyalty,
      loyaltyPoints,
      redeemables
    } = this.props;
    const { product, quantity, points } = this.state;

    // Assign cake topper image ID to specific it's product

    if (quickCheckout) {
      tagmanagerEvents.clickQuickCheckout({
        ...product,
        quantity,
        price: (product.price * quantity).toFixed(2)
      });
    } else {
      tagmanagerEvents.addToCart({
        ...product,
        quantity,
        price: (product.price * quantity).toFixed(2)
      });
    }

    let result;
    try {
      result = await orderRepo.addProductToOrder(orderCode, {
        product: product.id,
        quantity,
        redeemableId: loyalty === "redeem" ? redeemables?.redeemableId : null,
        dealId: loyalty === "deals" ? redeemables?.redeemableId : null
      });

      if ( loyalty ) {
        store.dispatch(loyaltyAction.loyaltyPoints(loyaltyPoints - points));
      }
    } catch (reason) {
      this.setState({ updateCartLoading: false });
      const isReason = reason.graphQLErrors ? getErrorReasons(reason, 0) : "";
      const pickupUnavailable =
        isReason === errorCodes.PICKUP_PRODUCT_UNAVAILABLE_REASON;
      const defaultErrorMessage = reason.graphQLErrors
        ? reason.graphQLErrors[0].message
        : reason.message;
      const errorMessage = pickupUnavailable
        ? productConstants.PICKUP_UNAVAILABLE_PRODUCT_MESSAGE
        : defaultErrorMessage;

      this.setState({
        productAddError: errorMessage
      });

      return;
    }

    const localStorageCart = result.data.addProductToOrderV2.items.map(
            (itemIn) => itemIn
    );
    setUserCart(localStorageCart);

    if (quickCheckout) {
      return result;
    }

    const addLink =
      userOrderMethod === orderMethods.shipping
        ? endpoints.shippingPage
        : endpoints.menu;
    history.push(routeCountryPath(addLink));
  };

  setCartStates = () => {
    const { history } = this.props;

    clearCart();
    history.push(routeCountryPath(endpoints.getMenuUrl(null)));
  };

  handleCartCreateError = reason => {
    const { setModalObject } = this.props;
    const message = getErrorMessages(reason, 0);
    const reasonType = getErrorReasons(reason, 0);
    const createReason = reasonType === errorCodes.CART_CREATE_ERROR;
    if (createReason) {
      setModalObject(openOrderUpdateFailModal(message, this.setCartStates));
    }
  };

  handleShipping = async () => {
    const { userCartId, setUserCartId } = this.props;

    if (userCartId) {
      return this.handleAddItemToOrder(userCartId);
    }

    const data = {
      orderTypeId: orderTypeIds.shipping,
      shippingMethod: shippingMethods.shipping,
      storeId: storeIds.shipping
    };

    try {
      const res = await orderRepo.createCart(data);

      const { createCart } = res.data;

      setUserCartId(createCart.code);

      return this.handleAddItemToOrder(createCart.id);
    } catch (reason) {
      this.handleCartCreateError(reason);
    }
  };

  handlePickupDelivery = async (quickCheckout = false) => {
    const {
      userCartId,
      selectedStore,
      userOrderMethod,
      setUserCartId
    } = this.props;

    if (selectedStore) {
      if (!userCartId) {
        let orderTypeId = null;
        let shippingMethod = null;
        if (userOrderMethod === orderMethods.delivery) {
          orderTypeId = 2;
          shippingMethod = 4;
        } else if (userOrderMethod === orderMethods.pickup) {
          orderTypeId = 3;
          shippingMethod = 3;
        }
        let storeId = null;
        if (selectedStore && selectedStore.id) {
          storeId = selectedStore.id;
        }

        const data = {
          orderTypeId,
          shippingMethod,
          storeId
        };

        try {
          const res = await orderRepo.createCart(data);
          const { createCart } = res.data;
          setUserCartId(createCart.code);
          return this.handleAddItemToOrder(createCart.code, quickCheckout);
        } catch (reason) {
          this.handleCartCreateError(reason);
        }
      }

      return this.handleAddItemToOrder(userCartId, quickCheckout);
    }

    this.setState({ updateCartLoading: false });
    return true;
  };

  handleAddItem = () => {
    const {
      userOrderMethod,
      setModalObject,
      updateProduct,
      loyaltyPoints
    } = this.props;
    const { points, product } = this.state;
    if (product.oos === 1) {
      return;
    }
    this.setState({ updateCartLoading: true });

    store.dispatch(loyaltyAction.loyaltyPoints(loyaltyPoints - points));

    if (updateProduct) {
      return this.handleProductUpdate();
    }

    if (userOrderMethod === orderMethods.shipping) {
      return this.handleShipping();
    }

    if (isDeliveryOrPickup(userOrderMethod)) {
      return this.handlePickupDelivery();
    }
    return setModalObject({
      children: (
        <div className={styles.widgetWrapper}>
          <DeliveryPickUpWidget isModal />
        </div>
      )
    });
  };

  handleQuickCheckout = async () => {
    const { product } = this.state;
    if (product.oos === 1) {
      return;
    }
    const { userOrderMethod, userCart, userCartId } = this.props;

    this.setCartLoading(true);

    if (isDeliveryOrPickup(userOrderMethod)) {
      const orderProductIds = _.pluck(userCart, "id");
      if (orderProductIds.length) {
        await orderRepo.removeProducts(userCartId, orderProductIds);
      }

      const [res] = await Promise.all([this.handlePickupDelivery(true)]);

      const { addProductToOrderV2 } = res.data;
      return { addProductToOrderV2 };
    }
  };

  setCartLoading = value => {
    this.setState({ updateCartLoading: value });
  };

  /**
   * Renders Single Product Container
   */
  singleProductContainer = () => {
    const {
      updateProduct,
      selectedStore,
      history,
      isCaloriesActive,
      currency,
      loyalty
    } = this.props;

    const {
      product,
      quantity,
      updateCartLoading,
      productAddError,
      productPrice,
      updatePriceLoading,
      points
    } = this.state;
    if (!product) {
      return (
        <div className={styles.pageLoaderWrapper}>
          <Loader />
        </div>
      );
    }
    const addProductText = selectedStore
      ? productConstants.ADD_TO_ORDER_TEXT
      : productConstants.ADD_TO_ORDER_NO_ADDRESS_TEXT;
    const addUpdateProductText = updateProduct
      ? productConstants.UPDATE_PRODUCT_TEXT
      : addProductText;
    const outOfStockText = productConstants.OUT_OF_STOCK_TEXT;
    const productPriceItem =
      selectedStore && !updatePriceLoading
        ? `${currency.symbol}${productPrice.toFixed(2)}`
        : null;
    const totalCost =
      selectedStore && !updatePriceLoading
        ? (productPrice * quantity).toFixed(2)
        : null;
    const isShippingStore =
      selectedStore && selectedStore.id === storeIds.shipping;
    const calories =
      isCaloriesActive && product.calories != null
        ? ` ${product.calories} kCal`
        : "";

    return (
      <div
        className={`${styles.productPageContainer} flex justify-between gap-8 md:p-0 px-4`}
      >
        <div className={styles.productImageContainer}>
          <img alt="product" src={product.productImage} />
        </div>
        <div className={`${styles.productDetailsContainer}`}>
          <div className={`${styles.productHeader}`}>
            <div className={styles.backMenu}>
              <h3
                className="w-full flex text-dark dark:text-white items-center gap-4 font-filsonProRegular font-bold mb-4"
                onClick={() =>
                  history.push(
                    routeCountryPath(
                      isShippingStore
                        ? endpoints.shippingPage
                        : endpoints.getMenuUrl(selectedStore)
                    )
                  )}
              >
                <span>
                  <img
                    alt="back"
                    src={backIcon}
                    className="block dark:hidden"
                  />
                  <img
                    alt="back"
                    src={lightBackIcon}
                    className="hidden dark:block"
                  />
                </span>
                Back to Menu
              </h3>
            </div>
            <h2 className="block md:hidden font-congenialRegular font-bold text-[44px] leading-[46px] tracking-[-1px] text-dark dark:text-white">
              {product.title}
            </h2>
            {loyalty ? (
              <span className=" text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
                {points}
                {' pts'}
                {renderLoyaltyPrice(this)}
              </span>
            ) : productPriceItem ? (
              product.strike_price ? (
                <span className=" text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
                  $
                  {currency.symbol}
                  <strike>{parseFloat(product.strike_price).toFixed(2)}</strike>
                  &nbsp;
                  {productPriceItem}
                </span>
              ) : (
                <span className=" text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
                  {productPriceItem}
                </span>
              )
            ) : null}

            <h2 className=" hidden md:block font-congenialRegular font-bold text-[44px] leading-[46px] tracking-[-1px] text-dark dark:text-white">
              {product.title}
            </h2>
          </div>
          <p className=" text-dark dark:text-white font-filsonProRegular text-lg leading-[22px] tracking-[-0.1px] ">
            {product.description}
            {' '}
            {calories}
          </p>
          {/* QUANTITY SELECTOR */}
          <div className={styles.productQuantityContainer}>
            <div>
              {/* NUTRITIONAL INFORMATION */}
              <a
                href={
                  product.nutritionInfoLink
                    ? product.nutritionInfoLink
                    : endpoints.nutritionalInfo
                }
                target="blank"
                className={`${styles.nutritionalInfo} text-[#983992] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px]`}
              >
                Nutritional info
              </a>
              { product.oos === 1 ? null : (
                <p className="text-dark dark:text-white font-filsonProBold text-lg leading-[22px] tracking-[-0.1px]">
                How many?
                </p>
              )}
              { product.oos === 1 ? null : (
                <p
                  onClick={() => this.setState({ quantity: 1 })}
                  className="text-[#983992] font-filsonProBold text-lg leading-[22px] tracking-[-0.1px]"
                >
                Clear selections
                </p>
              )}
            </div>

            <div
              className={`${styles.productQuantityChooseContainer} ${
                loyalty || product.oos === 1 ? "hidden" : ""
              }`}
            >
              <div className="w-11 h-11 rounded-full bg-dark dark:bg-light flex items-center">
                <img
                  onClick={() => this.handleQuantityChange()}
                  alt="minus"
                  src={minusIcon}
                  className="block dark:hidden m-auto w-6 h-6"
                />
                <img
                  onClick={() => this.handleQuantityChange()}
                  alt="minus"
                  src={darkMinusIcon}
                  className="dark:block hidden m-auto w-6 h-6"
                />
              </div>
              <span className="text-dark dark:text-white font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] w-2 h-[22px]">
                {quantity}
              </span>
              <div className="w-11 h-11 rounded-full bg-dark dark:bg-light flex items-center">
                <img
                  onClick={() => this.handleQuantityChange(true)}
                  alt="plus"
                  src={addIcon}
                  className="block dark:hidden m-auto w-6 h-6"
                />
                <img
                  onClick={() => this.handleQuantityChange(true)}
                  alt="plus"
                  src={darkAddIcon}
                  className="dark:block hidden m-auto w-6 h-6"
                />
              </div>
            </div>
          </div>

          {/* ACTIONS CONTAINER */}
          <div className={styles.cartWrapper}>
            {updateCartLoading ? (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            ) : null}

            <CheckoutCartButton
              alertMessage=""
              label={product.oos === 1 ? outOfStockText : addUpdateProductText}
              onClick={() => {
                this.handleAddItem();
              }}
              isDisabled={product.oos === 1 || updateCartLoading}
              price={loyalty ? points : totalCost}
              loyalty={loyalty}
            />

            <QuickCheckoutButton
              addProductToCart={this.handleQuickCheckout}
              setCartLoading={this.setCartLoading}
              isDisabled={product.oos === 1 || updateCartLoading}
              product={product}
              history={history}
            />
            {productAddError.length ? (
              <p className={styles.errorMessage}>{productAddError}</p>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <this.singleProductContainer />;
  }
}

SingleProduct.propTypes = {
  isCaloriesActive: PropTypes.bool,
  isShowOosProducts: PropTypes.bool,
  currency: PropTypes.objectOf(PropTypes.string)
};

SingleProduct.defaultProps = {
  isCaloriesActive: false,
  isShowOosProducts: false,
  currency: defaultCurrency
};

export const mapDispatchToProps = dispatch => ({
  setUserCart: value => dispatch(userActions.setUserCart(value)),
  setUserCartId: value => dispatch(userActions.setUserCartId(value)),
  setModalObject: value => dispatch(elementActions.setModalObject(value)),
  setStoreId: value => dispatch(userActions.setSelectedStore(value)),
  setUserOrderMethodId: value => dispatch(userActions.setUserOrderMethod(value))
});

export const mapStateToProps = state => {
  const {
    userCart,
    userCartId,
    userInfo,
    selectedStore,
    userOrderMethod
  } = state.user;
  const { designId } = state.customCakeTopper;
  const { isCaloriesActive, isShowOosProducts } = state.product;
  const { currency } = state.currency;
  const { loyaltyRedeemables, loyaltyDeals, loyaltyPoints, redeemables } = state.loyalty;
  const { order } = state;
  return {
    userCart,
    userCartId,
    userInfo,
    selectedStore,
    userOrderMethod,
    designId,
    isCaloriesActive,
    isShowOosProducts,
    currency,
    loyaltyRedeemables,
    loyaltyDeals,
    loyaltyPoints,
    order,
    redeemables
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleProduct)
);
