/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ME_HEADER_QUERY } from "../../graphql/user";
import endpoints from "../../shared/constants/endpoints";
import { orderMethods } from "../../shared/constants/order";
import Link from "../LinkWithCountry/LinkWithCountry";

import { doughProCategory } from "../../shared/repos/graphql/doughPro";
import {
  checkoutNoDeliveryAddressSelected,
  checkoutOrderMinAmount,
  checkoutWithNoItems
} from "../../shared/utilities/orders";

import * as elementsActions from "../../redux/actions/elements";
import * as userActions from "../../redux/actions/user";

import ProductsCategoryFilter from "../ProductsCategoryFilter/ProductsCategoryFilter";

import { modalBox } from "../../shared/constants/refs";
import { isMobile, routeCountryPath } from "../../shared/utilities/common";
import {
  confirmToMenuPage,
  goBackToMain,
  menuStoreChange
} from "../../shared/utilities/store";

import { storeIds } from "../../shared/constants/store";
import RibbonBanner from "../RibbonBanner/RibbonBanner";
import styles from "./Header.module.css";

import cmsKeys from "../../shared/constants/cms";
import { METADATA_INFO } from "../../shared/constants/common";
import { defaultCurrency } from "../../shared/constants/currency";
import siteSetting from "../../shared/constants/siteSetting";
import * as cms from "../../shared/repos/cms/cms";
import * as orderRepo from "../../shared/repos/graphql/order";
import DeliveryPickUpWidget from "../DeliveryPickUpWidget/DeliveryPickUpWidget";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";
import { store } from "../../redux/store";
import orderDispatches from "../../shared/utilities/order-dispatches";
import * as loyaltyRepo from "../../shared/repos/graphql/loyalty";
import * as storeRepo from "../../shared/repos/graphql/store";
import * as loyaltyAction from '../../redux/actions/loyalty';
import BarcodeWidget from '../BarcodeWidget/BarcodeWidget';
import { EZDINE, PUNCHH } from "../../shared/constants/loyaltyType";
import { LABELS } from "../../shared/constants/labels";

const images = [];
images["app-logo"] = require("./imgs/app-logo.png");
images.logo = require("./imgs/logo.png");
images["app-icon-light"] = require("./imgs/cart-icon-light.png");
images["cart-icon"] = require("./imgs/cart-icon.png");
images["cart-icon-light"] = require("./imgs/cart-icon-light.png");
images["dropdown-icon-light"] = require("./imgs/dropdown-icon-light.png");
images["dropdown-icon"] = require("./imgs/dropdown-icon.png");
images["nav-icon-light"] = require("./imgs/nav-icon-light.png");
images["nav-icon"] = require("./imgs/nav-icon.svg");
images["user-icon-light"] = require("./imgs/user-icon-light.png");
images["user-icon"] = require("./imgs/user-icon.svg");
const DeliveryIcon = require("./imgs/delivery.svg");
images["login-icon"] = require("./imgs/login.svg");
const PickupIcon = require("./imgs/pickup.png");
const locationIcon = require("./imgs/map-pin.svg");
const giftIcon = require("./imgs/gift.svg");

class Header extends Component {

  scrollUpValue = 0;

  constructor(props) {
    super(props);
    this.state = {
      content: [],
      renderNotification: false,
      showNotification: false,
      amountAdded: null,
      openDeliveryWidget: false,
      showDeliveryWidget: false,
      deliveryPickupButton: "delivery",
      isScrollDirectionIsUp: false,
    };
    this.headerElement = React.createRef();
  }

  async componentDidMount() {
    const {
      userAddress,
      location,
      toggleHeaderWidget,
      selectedStore,
      userOrderMethod,
      hasError,
      setDoughProSpecialCategory,
      userToken, activeLoyalty
    } = this.props;
    if (!userAddress) {
      if (location.pathname === "/home/address") {
        this.setState({
          showDeliveryWidget: true
        });
      }
    }

    if (
      location.pathname.includes("/products") &&
      !userAddress &&
      userOrderMethod !== orderMethods.shipping
    ) {
      toggleHeaderWidget();
    }

    if (!hasError) {
      doughProCategory()
        .then(res => {
          if (res && !res.loading && res.data) {
            setDoughProSpecialCategory(res.data.doughProSpecialCategory);
          }
        })
        .catch(() => {});
    }

    this.initializeRouteChangeListener();
    this.checkStickiness();
    this.checkDeliveryPickupStickness();
    this.handleStickySetting();

    if ( userToken && activeLoyalty === PUNCHH) {
      await this.getLoyaltyRedeemables();
      await this.getLoyaltyDeals();
      await this.getLoyaltyPoints();
    }

    window.addEventListener("click", this.handleOutsideClick);
    cms.getContent([cmsKeys.placeholders.enterDeliveryAddress], this);
    if (userAddress && !selectedStore) {
      this.setStateDispatchers();
    }
    this.fetchStoreCloseTimer();
    this.intervalId = setInterval(this.fetchStoreCloseTimer, 60000);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { pathname } = location;
    if (pathname !== prevProps.location.pathname) {
      this.checkStickiness();
    }
    this.handleStickySetting();
    this.handleCartAmountUpdate(prevProps);
  }

  componentWillUnmount() {
    const { setStickyCategories } = this.props;
    window.removeEventListener("scroll", this.handleStickySetting); // eslint-disable-line
    setStickyCategories(false);
    this.unlisten();
    clearInterval(this.intervalId);
  }

  fetchStoreCloseTimer = async () => {
    const { userCartId } = this.props;
    if (userCartId) {
      const response = await storeRepo.getStoreCloseTimer(userCartId);
      if (response?.data) {
        store.dispatch(elementsActions.setCloseTimer(response.data))
      }
    }
  };

  initializeRouteChangeListener = () => {
    const { history } = this.props;
    this.unlisten = history.listen(() => {
      this.checkStickiness();
    });
  };

  getLoyaltyRedeemables = async () => {
    const response = await loyaltyRepo.getLoyaltyRedeemables();
    store.dispatch(loyaltyAction.loyaltyRedeemables(response.data.loyaltyRedeemables))
  };

  getLoyaltyDeals = async () => {
    const response = await loyaltyRepo.getLoyaltyDeals();
    store.dispatch(loyaltyAction.loyaltyDeals(response.data.loyaltyDeals))
  };

  getLoyaltyPoints = async () => {
    const response = await loyaltyRepo.getLoyaltyProfile();
    this.getRemainingPointBalance(response.data.loyaltyProfile.points);
  };

  getRemainingPointBalance = (points) => {
    const { order } = this.props;
    let loyaltyPoints = points;
    if (order && order?.orderRedeemables?.length > 0) {
      const usedPoints = order.orderRedeemables.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.points;
      }, 0);

      loyaltyPoints = points - usedPoints;
    }

    store.dispatch(
      loyaltyAction.loyaltyPoints(loyaltyPoints)
    );
  }

  setStateDispatchers = () => {
    const {
      setModalObject,
      setUserAddress,
      setSelectedStore,
      setUserOrderMethod
    } = this.props;
    setSelectedStore(null);
    setUserAddress(null);
    setUserOrderMethod("");
    setModalObject(null);
  };

  handleCartAmountUpdate = prevProps => {
    const { userCart } = this.props;
    const { userOrderMethod } = prevProps;
    const oldCartAmount = prevProps.userCart
      .map(item => item.quantity)
      .reduce((a, b) => a + b, 0);
    const newCartAmount = userCart
      .map(item => item.quantity)
      .reduce((a, b) => a + b, 0);

    if (userOrderMethod && oldCartAmount < newCartAmount) {
      this.setState({
        renderNotification: true,
        amountAdded: newCartAmount - oldCartAmount
      });
      setTimeout(() => this.setState({ showNotification: true }), 100);
      setTimeout(() => this.setState({ showNotification: false }), 4000);
      setTimeout(() => this.setState({ renderNotification: false }), 4500);
    }
  };

  handleStickySetting = () => {
    const { setStickyCategories, categoriesPosition } = this.props;

    if (this.isStickyCategoriesEnabled())
      return setStickyCategories(
        window.pageYOffset >= (categoriesPosition || 0) - 100
      ); // eslint-disable-line

    return null;
  };

  returnIconTheme = (theme, icon, className = null) => {
    return (
      <img
        alt={`${theme}-${icon}`}
        src={images[`${icon}${theme === "light" ? "-light" : ""}`]}
        className={className}
      />
    );
  };

  toggleWidget = () => {
    const { toggleHeaderWidget } = this.props;
    toggleHeaderWidget();
  };

  toggleAddress = () => {
    this.setState({
      openDeliveryWidget: true
    });
    this.toggleWidget();
  };

  isStickyCategoriesEnabled = () => {
    const { location } = this.props;

    return (
      location.pathname === "/" ||
      location.pathname.includes("/menu") ||
      location.pathname === "/shipping" ||
      new RegExp(`^[/][a-z]{2}\\/?$`).test(location.pathname) ||
      new RegExp(`^[/][a-z]{2}\\/shipping$`).test(location.pathname)
    );
  };

  handleMenuClick = e => {
    e.preventDefault();
    const { location, history, selectedStore } = this.props;
    const menuLink = endpoints.getMenuUrl(selectedStore);

    const isStoreMenuPage = location.pathname.includes(endpoints.menu);
    const isShipingMenuPage = location.pathname.includes(endpoints.shippingPage)

    const isGiftStore = selectedStore && selectedStore.id === storeIds.giftCard;
    if (isStoreMenuPage || isGiftStore) {
      menuStoreChange(history.push, routeCountryPath(endpoints.homePage));
    }

    if (isShipingMenuPage) {
      this.handleStoreMenu(e);
      return;
    }

    if (selectedStore) {
      const isShippingMenu = selectedStore.id === storeIds.shipping;
      const menuPath = isShippingMenu ? `${endpoints.shippingPage}` : menuLink;
      history.push(routeCountryPath(menuPath));
    } else {
      history.push(routeCountryPath(menuLink));
    }
  };

  confirmViewMenu = e => {
    e.preventDefault();
    const { history, selectedStore, userAddress } = this.props;
    const menuLink = endpoints.getMenuUrl(selectedStore);
    const address = userAddress.address1
      ? userAddress.address1
      : selectedStore?.name;
    confirmToMenuPage(
      history.push,
      this.handleDeliveryWidget,
      address,
      routeCountryPath(menuLink)
    );
  };

  handleStoreMenu = e => {
    e.preventDefault();
    const {
      history,
      selectedStore,
      userAddress,
      location,
      userCart,
      userOrderMethod
    } = this.props;

    const isShipingMenuPage = location.pathname.includes(
      endpoints.shippingPage
    );
    if (isShipingMenuPage || userOrderMethod === orderMethods.shipping) {
      if (userCart.length > 0) {
        goBackToMain(history.push, routeCountryPath(endpoints.homePage));
      } else {
        orderDispatches(store, userActions, elementsActions);
        history.push(routeCountryPath(endpoints.homePage));
      }
      return;
    }

    if (userAddress) {
      const menuLink = endpoints.getMenuUrl(selectedStore);
      const isShippingMenu = selectedStore?.id === storeIds.shipping;
      const menuPath = isShippingMenu ? `${endpoints.shippingPage}` : menuLink;
      history.push(routeCountryPath(menuPath));
    } else {
      this.toggleAddress();
    }
  };

  handleOutsideClick = event => {
    const { showHeaderWidget, toggleHeaderWidget } = this.props;
    const { openDeliveryWidget } = this.state;
    const canToggleWidget =
      showHeaderWidget &&
      !openDeliveryWidget &&
      modalBox &&
      !modalBox?.current?.contains(event.target) &&
      !document
        .getElementsByClassName(styles.deliveryContainer)[0]
        ?.contains(event.target);
    if (canToggleWidget) {
      toggleHeaderWidget();
    }
    this.setState({
      openDeliveryWidget: false
    });
  };

  handleCartIconLink = async e => {
    e.preventDefault();
    const {
      userCart,
      userAddress,
      history,
      userCartId,
      userOrderMethod,
      orderMinAmount,
      currency,
      setOrderMinAmount
    } = this.props;
    if (
      !userAddress &&
      userCart.length <= 0 &&
      userOrderMethod !== orderMethods.shipping
    ) {
      checkoutNoDeliveryAddressSelected(this.toggleAddress);
      return;
    }

    let isMinAmount = false;

    const cartIconLink = endpoints.checkoutOrderPage.replace(":id", userCartId);

    if (userOrderMethod === orderMethods.delivery) {
      let minAmount = orderMinAmount;
      if (userCart.length <= 0) {
        checkoutOrderMinAmount(minAmount, currency.symbol);
        return;
      }

      if (userCart.length > 0) {
        if (!minAmount) {
          const order = await orderRepo.getOrder(userCartId);
          minAmount = order?.data?.order?.shippingMethodMinAmount;
          setOrderMinAmount(order?.data?.order?.shippingMethodMinAmount);
        }

        const totalPrice = userCart
          .map((item) => {
            const itemPrice = item.quantity * item.price;
            let optionsPrice = 0;
            if (item?.productOptions && item.productOptions.length > 0){
              optionsPrice = item.productOptions
                .map(option => option.quantity * (option.optionPrice ? option.optionPrice : 0))
                .reduce((a, b) => a + b, 0);
            }
            return itemPrice + optionsPrice;
          })
          .reduce((a, b) => a + b, 0);
        if (totalPrice < minAmount) {
          isMinAmount = true;
          checkoutOrderMinAmount(minAmount, currency.symbol);
        }
      }
    } else if (userCart.length <= 0) {
      checkoutWithNoItems();
      return;
    }
    if (!isMinAmount) {
      history.push(routeCountryPath(cartIconLink));
    }
  };

  handleDeliveryWidget = value => {
    this.setState({
      showDeliveryWidget: value
    });
  };

  handleDeliveryPickupButton = value => {
    this.setState({
      deliveryPickupButton: value
    });
  };

  userAuthenticationButton = (
    history,
    loggedIn,
    theme,
    data,
    userFirstname,
    userLastname,
    signInButtonStyleChange,
    signInButtonText,
    existUserFullName,
    isMenu = true
  ) => {
    const { activeLoyalty } = this.props;
    return (
      <div
        className={`${styles.userContainer}  ${
          loggedIn ? styles.userContainerLoggedIn : null
        }`}
      >
        {loggedIn ? (
          <Link to="/user/profile">
            <div
              className={[
                styles.userDetails,
                "gap-2 -mr-2 px-4 py-1 hidden md:flex",
                `${!isMenu ? "border-white text-white" : ""}`
              ].join(" ")}
            >
              <img alt={`${theme}-user-icon`} src={images["user-icon"]} />

              <div className="flex items-center justify-center">
                <h3>{`${userFirstname} ${userLastname}`}</h3>
                {
                  activeLoyalty === EZDINE ? (
                    <>
                      <span className="bg-white h-1 w-1 rounded-full mx-1" />
                      <h3>{`${data.me.loyalty.points}pts`}</h3>
                    </>
                  ) : null
                }
              </div>
            </div>
          </Link>
        ) : (
          <>
            <button
              onClick={() => history.push(routeCountryPath("/login"))}
              className={`${styles.button} ${
                signInButtonStyleChange ? styles.signInButton : ""
              } lg:block hidden`}
              type="button"
            >
              {signInButtonText}
            </button>
            <p
              onClick={() => history.push(routeCountryPath("/login"))}
              className={`${!loggedIn ? '-mr-3' : ''} block lg:hidden`}
            >
              {this.returnIconTheme(null, "login-icon")}
            </p>
          </>
        )}
        {loggedIn ? (
          <Link to="/user/profile">
            <div className={styles.mobileDetailsWrapper}>
              <div
                className={[
                  styles.userDetailsMobile,
                  !existUserFullName ? styles.noBorder : null
                ].join(" ")}
              >
                {existUserFullName ? (
                  <p>{`${userFirstname[0]}  ${userLastname[0]}`}</p>
                ) : (
                  this.returnIconTheme(theme, "user-icon")
                )}
              </div>
              {activeLoyalty === EZDINE && <span>{`${data.me.loyalty.points}pts`}</span>}
            </div>
          </Link>
        ) : null}
      </div>
    );
  };

  userCartLink = (
    cartIconLink,
    cartQuantity,
    userCart,
    renderNotification,
    showNotification,
    amountAdded,
    isMenu = true
  ) => {
    return (
      <Link to={cartIconLink} onClick={this.handleCartIconLink}>
        <div
          id="cart-container"
          className={`${
            styles.cartContainer
          } h-[50px] w-[50px] flex items-center ${
            !isMenu ? "bg-dark rounded-full" : ""
          } hidden md:block`}
        >
          <img
            id="cart-icon"
            data-ddog-id="cart_icon"
            src={images["cart-icon"]}
            alt="cart-icon"
            className="h-[50px] w-[50px] object-cover"
          />
          <span
            id="cart_quantity"
            data-ddog-id="cart_quantity"
            className={`${styles.cartQuantity} absolute ${
              !isMenu ? "right-[33%]" : "right-[20%]"
            } top-[28%] w-4 text-center`}
          >
            {cartQuantity}
          </span>
          {userCart.length === 0 ? (
            <div
              className={`
                      ${styles.cartAlertContainer}
                      py- bg-light text-dark
                      zIndex
                      `}
            >
              <p className="py-2 ">No items in your cart</p>
            </div>
          ) : null}
          <div
            className={[
              renderNotification ? styles.renderNotification : null,
              showNotification ? styles.showNotification : null,
              styles.cartNotificationContainer,
              "zIndex text-white"
            ].join(" ")}
          >
            <p className="py-3">
              {`${
                amountAdded === 1
                  ? `${amountAdded} Item added`
                  : `${amountAdded} Items added`
              }`}
            </p>
          </div>
        </div>
      </Link>
    );
  };

  userRewardLink = (isMenu, theme) => {
    const { newRedeemableLvlUnlocked, newOfferAvailable } = this.props;

    const showIndicator = (newRedeemableLvlUnlocked || newOfferAvailable);

    return (
      <div
        className={`${styles.userContainer}`}
      >
        <Link to="/user/loyalty-rewards">
          <div
            className={[
              styles.loyaltyReward,
              "gap-2 px-4 py-1 flex",
              `${!isMenu ? "border-white text-white" : ""}`
            ].join(" ")}
          >
            <img
              alt={`${theme}-user-icon`}
              className="hidden md:block"
              src={giftIcon}
            />

            <div className="flex items-center justify-center">
              <h3>Rewards</h3>
              { showIndicator && (
                <span className="w-4 h-4 border-2 rounded-full border-dark bg-button -mr-5 -mt-6" />
              )}
            </div>
          </div>
        </Link>
      </div>
    );
  };

  header = () => {
    const {
      userCart,
      theme,
      hasActiveOrders,
      noActiveOrders,
      toggleMobileMenu,
      showHeaderWidget,
      isStickyCategories,
      isBetaCustomer,
      userToken,
      userOrderMethod,
      userAddress,
      setUserId,
      setBetaCustomer,
      history,
      location,
      setDoughProMember,
      isDoughProActive,
      userInfo,
      userCartId,
      shouldDisplayIntroBanner,
      footerMenuItemsLinksSettings,
      footerMenuItemsSettings,
      pathname,
      headRef,
      showDeliveryPickupWidget,
      hideHeaderMenuOnScroll,
      selectedStore,
      themeSettings,
      activeLoyalty,
      closeTimer
    } = this.props;

    const {
      amountAdded,
      showNotification,
      renderNotification,
      content,
      showDeliveryWidget,
      deliveryPickupButton,
      isScrollDirectionIsUp
    } = this.state;

    let headerQueryResponse = {};
    if (userToken) {
      headerQueryResponse = useQuery(ME_HEADER_QUERY, {
        fetchPolicy: "no-cache",
        variables: { language: "english" },
        onCompleted: res => {
          setUserId(res.me.id);
        }
      });
    }

    const { data, loading } = headerQueryResponse || {
      data: null,
      loading: false
    };

    let loggedIn = false;
    if (!loading) {
      loggedIn =
        userToken !== null && userToken !== undefined && data && data.me;
      setBetaCustomer(loggedIn && data.me && data.me.isBetaCustomer);
    }

    const firstname = data?.me?.firstname || "";
    const lastname = data?.me?.lastname || "";
    const userFirstname = userInfo?.user?.firstName || firstname;
    const userLastname = userInfo?.user?.lastName || lastname;
    const existUserFullName = !!(userFirstname && userLastname);

    if (loggedIn && data.me.isBetaCustomer) {
      setDoughProMember(data.doughProMember);
    }

    const cartQuantity = userCart
      .map(item => item.quantity)
      .reduce((a, b) => a + b, 0);

    // const currentOrderNotShipping = userOrderMethod
    //   ? userOrderMethod !== orderMethods.shipping
    //   : false;
    const showStickyCategories =
      isStickyCategories && !(isMobile() && showHeaderWidget);

    // const currentOrderDoughPro = userOrderMethod
    //   ? userOrderMethod === orderMethods.doughPro
    //   : false;

    // const shippingWithoutPrompt =
    //   currentOrderNotShipping && !currentOrderDoughPro;

    const cartIconLink =
      userCart.length > 0
        ? endpoints.checkoutOrderPage.replace(":id", userCartId)
        : endpoints.checkoutViewOrderPage;

    const ribbonBanner = !shouldDisplayIntroBanner ? (
      <div className={`${styles.ribbonBannerWrapper}`}>
        <RibbonBanner />
      </div>
    ) : null;

    let signInButtonText = LABELS.SIGN_IN;
    let signInButtonStyleChange = false;
    if (window.innerWidth < 430) {
      signInButtonText = LABELS.SIGN_IN_MOBILE;
      signInButtonStyleChange = true;
    }

    const disableCssClass =
      " opacity-60 bg-opacity-60 border-opacity-60 text-opacity-60";
    const hoverCssClass =
      " hover:opacity-100 hover:bg-opacity-100 hover:border-opacity-100 hover:text-opacity-100";
    const userLoggedIn = (userToken && (isBetaCustomer || isDoughProActive));
    const userHasActiveOrders = userLoggedIn && data?.me?.activeOrders?.totalCount > 0;

    const handleMobileNavClick = async () => {
      if(userHasActiveOrders) {
        await hasActiveOrders();
      } else {
        await noActiveOrders();
      }

      toggleMobileMenu();
    }

    return (
      <div className={`${styles.headerWrapper} border-mainDark`} ref={headRef}>
        {ribbonBanner}

        {hideHeaderMenuOnScroll && (
          <div
            className={`${styles.headerContainer} ${
              theme === "light" ? styles.isLight : styles.isDark
            } h-20 md:h-[68px] w-full bg-dark border-b border-[#5A3770] py-3`}
          >
            <div className="max-w-screen-desktop mx-auto">
              <div className="flex flex-row items-center justify-between md:px-0 px-4">
                <div className={`${styles.headerLeft} flex gap-2`}>
                  <Link onClick={this.handleMenuClick} to={location.pathname}>
                    <img
                      src={images["app-logo"]}
                      alt="Logo"
                      className="block md:hidden h-[60px] object-cover"
                    />
                    <img
                      src={images.logo}
                      alt="Logo"
                      className="hidden md:block h-10 object-cover "
                    />
                  </Link>
                  {showHeaderWidget ? (
                    <div className={styles.widgetWrapper} ref={modalBox}>
                      <DeliveryPickUpWidget cmsContent={content} />
                    </div>
                  ) : null}
                </div>

                <div
                  className={`${styles.headerRight} ${
                    theme === "light" ? styles.isLight : styles.isDark
                  } flex items-center md:gap-5 gap-3 text-white font-filsonProRegular`}
                >
                  {themeSettings[siteSetting.IS_THEME_SWITCH_ENABLED] && (
                    <ThemeSwitch />
                  )}
                  <p>
                    {/* eslint-disable-next-line  */}
                    <a name="menu_link" onClick={this.handleStoreMenu}>
                      Menu
                    </a>
                  </p>

                  {
                    userLoggedIn && (
                      <p>
                        <Link id="ordersPageLink" to={endpoints.orders} className={`${styles.ordersLink}`}>
                          {LABELS.ORDERS}

                          {userHasActiveOrders && (
                            <span className={`${styles.ordersAttentionGrabber}`} />
                          )}
                        </Link>
                      </p>
                    )
                  }

                  <p>
                    <Link to={endpoints.locations}>
                      {METADATA_INFO.HEADER_LINK.LOCATION}
                    </Link>
                  </p>

                  { !userLoggedIn && (
                    <p>
                      <Link to={endpoints.rewardLandingPage}>{LABELS.REWARDS}</Link>
                    </p>
                  )}

                  {footerMenuItemsSettings[
                    siteSetting.IS_FOOTER_MENU_MERCH_ENABLED
                  ] && (
                    <p>
                      <a
                        name="merch_link"
                        href={
                          footerMenuItemsLinksSettings[
                            siteSetting.FOOTER_MENU_MERCH_LINK
                          ]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Merch
                      </a>
                    </p>
                  )}

                  {footerMenuItemsSettings[
                    siteSetting.IS_FOOTER_MENU_CAREERS_ENABLED
                  ] && (
                    <p>
                      <a
                        name="careers_link"
                        href={
                          footerMenuItemsLinksSettings[
                            siteSetting.FOOTER_MENU_CAREERS_LINK
                          ]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Careers
                      </a>
                    </p>
                  )}
                  {(loggedIn && activeLoyalty === 'punchh') && this.userRewardLink(false, theme)}
                  {/* Sign In button */}
                  {this.userAuthenticationButton(
                    history,
                    loggedIn,
                    theme,
                    data,
                    userFirstname,
                    userLastname,
                    signInButtonStyleChange,
                    signInButtonText,
                    existUserFullName
                  )}
                  {/* Cart Link */}
                  {this.userCartLink(
                    cartIconLink,
                    cartQuantity,
                    userCart,
                    renderNotification,
                    showNotification,
                    amountAdded
                  )}
                  <div
                    onClick={handleMobileNavClick}
                    className={styles.navContainer}
                  >
                    {this.returnIconTheme(theme, "nav-icon")}

                    {userHasActiveOrders && (
                      <span className={`${styles.ordersAttentionGrabber}`} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(userAddress ||
            showDeliveryPickupWidget ||
            pathname !== "/" ||
            isMobile()) &&
          pathname && !pathname.match(/login/g) && !pathname.match(/create-account/g) &&
          !pathname.match(/checkout\/review-order/g) && pathname !== endpoints.locations
          && pathname !== endpoints.doughProPage && !pathname.match(endpoints.orderLoyaltyRegex) && (
            <div className="bg-dark border-mainDark shadow-md ">
              <div className="flex md:max-w-screen-desktop md:px-0 px-4 m-auto py-[6px] ">
                {userAddress && userOrderMethod !== orderMethods.shipping ? (
                  <div className="flex gap-2 items-center flex-1 md:h-12">
                    {userOrderMethod === orderMethods.pickup ? (
                      <img
                        src={PickupIcon}
                        alt="pickup"
                        className="md:block hidden"
                      />
                    ) : (
                      <img
                        src={DeliveryIcon}
                        alt="delivery"
                        className="md:block hidden"
                      />
                    )}
                    <div className="text-white font-filsonProRegular">
                      <div className="md:flex items-center gap-3 ">
                        <p className="md:py-2">
                          {userOrderMethod !== orderMethods.pickup
                            ? "Warm Cookie Delivery"
                            : "Delicious Cookie Pickup"}
                        </p>
                        <p className="font-bold flex gap-2 items-center">
                          {userOrderMethod === orderMethods.pickup
                          ? selectedStore?.name
                          : (userAddress.address1 ? userAddress.address1 : selectedStore?.name)}

                          {pathname !== "/" && !pathname.match(/profile/g) ? (
                            // eslint-disable-next-line
                            <a
                              onClick={() => this.handleDeliveryWidget(true)}
                              className="text-dryPink underline cursor-pointer"
                            >
                              Change
                            </a>
                          ) : (
                            // eslint-disable-next-line
                            <a
                              onClick={this.confirmViewMenu}
                              className="text-dryPink underline cursor-pointer text-xs lg:text-[16px]"
                            >
                              View Menu
                            </a>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : userOrderMethod !== orderMethods.shipping ? (
                  (showDeliveryPickupWidget ||
                    pathname !== "/" ||
                    isMobile()) && (
                    <div
                      className={`md:flex gap-2 items-center md:h-12 ${
                        showDeliveryPickupWidget && isScrollDirectionIsUp
                          ? "h-12"
                          : "h-24"
                      } flex-1 mr-2`}
                    >
                      <div
                        className={`flex gap-2  ${
                          showDeliveryPickupWidget && isScrollDirectionIsUp
                            ? ""
                            : "md:mb-0 mb-2"
                        }`}
                      >
                        <button
                          type="button"
                          id="deliveryWidget"
                          onClick={() =>
                            this.handleDeliveryPickupButton("delivery")}
                          className={` ${
                            showDeliveryPickupWidget && isScrollDirectionIsUp
                              ? "hidden md:flex"
                              : "flex"
                          }
                      flex-row justify-center items-center
                    md:w-[183px] w-full px-4 py-1 gap-2 h-11 bg-transparent border
                    border-borderLight border-solid rounded-[54px] text-white
                    font-filsonProBold ${
                      deliveryPickupButton === "delivery"
                        ? "opactity-100"
                        : disableCssClass
                    }
                    ${hoverCssClass}
                    `}
                        >
                          <img src={DeliveryIcon} alt="delivery" />
                          Delivery
                        </button>
                        <button
                          type="button"
                          id="pickupWidget"
                          onClick={() =>
                            this.handleDeliveryPickupButton("pickup")}
                          className={` ${
                            showDeliveryPickupWidget && isScrollDirectionIsUp
                              ? "hidden md:flex"
                              : "flex"
                          }
                    flex flex-row justify-center items-center
                    md:w-[183px] w-full px-4 py-1 gap-2 h-11 bg-transparent border
                    border-borderLight border-solid rounded-[54px]
                    text-white font-filsonProBold
                    ${
                      deliveryPickupButton === "pickup"
                        ? "opactity-100 bg-[none]"
                        : disableCssClass
                    }
                    ${hoverCssClass}
                    `}
                        >
                          <img src={PickupIcon} alt="pickup" />
                          Pickup
                        </button>
                      </div>
                      <div
                        className="flex flex-row items-center gap-3 h-11 border
                border-mainDark rounded-[54px] py-3 px-4 w-full lg:w-[400px] text-white
                leading-5 bg-gradient-l bg-gradient-to-l from-darkBlack
                  to-darkBlack cursor-pointer"
                        onClick={() => this.handleDeliveryWidget(true)}
                      >
                        <img
                          className="w-5 h-5"
                          alt="location-icon"
                          src={locationIcon}
                        />
                        <p>{`Enter ${deliveryPickupButton} address`}</p>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="md:flex gap-2 items-center flex-1 md:h-12 mr-2">
                    <p>
                      <button
                        type="button"
                        onClick={this.handleStoreMenu}
                        className="rounded-40 py-2 px-4
                        border-[1px] border-solid border-darkDry text-white"
                      >
                        Return to Delivery and Pickup
                      </button>
                    </p>
                  </div>
                )}

                {!hideHeaderMenuOnScroll && !isMobile() && (
                  <div className={`${styles.headerAddress} flex gap-8 h-12`}>
                    {(loggedIn && activeLoyalty === 'punchh') && this.userRewardLink(false, theme)}
                    {/* Sign In button */}
                    {this.userAuthenticationButton(
                      history,
                      loggedIn,
                      theme,
                      data,
                      userFirstname,
                      userLastname,
                      signInButtonStyleChange,
                      signInButtonText,
                      existUserFullName,
                      false
                    )}
                    {/* Cart Link */}
                    {this.userCartLink(
                      cartIconLink,
                      cartQuantity,
                      userCart,
                      renderNotification,
                      showNotification,
                      amountAdded,
                      false
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        {showDeliveryWidget ? (
          <DeliveryPickUpWidget
            isVisible={showDeliveryWidget}
            onClose={() => this.handleDeliveryWidget(false)}
            addressType={deliveryPickupButton}
            cmsContent={content}
          />
        ) : null}

        {showStickyCategories ? (
          <div
            className={`${styles.categoriesWrapper} ${
              pathname === "/"
                ? "hidden"
                : "w-full bg-dark rounded-none border-t-2 border-solid border-[#5A3770]"
            }`}
          >
            <div className="lg:max-w-screen-desktop px-4 lg:px-0 m-auto">
              <ProductsCategoryFilter mobileDarkTheme onChange={() => null} />
            </div>
          </div>
        ) : null}

        <BarcodeWidget />
        {closeTimer && closeTimer.timerShouldShow && (
          <div
            className="flex md:px-0 px-4 m-auto"
            style={{ backgroundColor: 'rgb(224, 23, 102)' }}
          >
            <div className='flex md:max-w-screen-desktop md:px-0 px-4 m-auto py-1'>
              {/* eslint-disable-next-line react/no-danger */}
              <p className="font-filsonProBold mx-auto" dangerouslySetInnerHTML={{ __html: closeTimer.timerMessage }} />
            </div>
          </div>
        )}
      </div>
    );
  };

  checkStickiness = () => {
    const { categoriesPosition, setStickyCategories } = this.props;

    if (this.isStickyCategoriesEnabled()) {
      setStickyCategories(window.pageYOffset >= categoriesPosition - 100); // eslint-disable-line

      window.addEventListener("scroll", this.handleStickySetting); // eslint-disable-line
    } else {
      window.removeEventListener("scroll", this.handleStickySetting); // eslint-disable-line
      setStickyCategories(false);
    }
  };

  handleScrollDeliveryPickupWidget = () => {
    const { setDeliveryPickupAddressWidget } = this.props;
    const { isScrollDirectionIsUp } = this.state;
    const isScroll = window.pageYOffset >= 250;
    const windowScroll = window.scrollY;
    let isScrollDirectionIsUpVal = false;
    if(this.scrollUpValuel < windowScroll && isMobile()) {
      isScrollDirectionIsUpVal = true
    }
    this.scrollUpValuel = windowScroll;
    if (isScrollDirectionIsUpVal !== isScrollDirectionIsUp) {
      this.setState({
        isScrollDirectionIsUp: isScrollDirectionIsUpVal
      });
    }

    return setDeliveryPickupAddressWidget({
      showDeliveryPickupWidget: isScroll,
      hideHeaderMenuOnScroll: !isScroll
    });
  };

  checkDeliveryPickupStickness = () => {
    window.addEventListener("scroll", this.handleScrollDeliveryPickupWidget);
  };

  render() {
    return <this.header />;
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  theme: PropTypes.string,
  hasActiveOrders: PropTypes.func.isRequired,
  noActiveOrders: PropTypes.func.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
  pathname: PropTypes.string,
  toggleHeaderWidget: PropTypes.func,
  showHeaderWidget: PropTypes.bool,
  isStickyCategories: PropTypes.bool,
  setStickyCategories: PropTypes.func,
  categoriesPosition: PropTypes.number,
  userToken: PropTypes.string,
  userOrderMethod: PropTypes.string,
  userAddress: PropTypes.shape({ address1: PropTypes.string }),
  selectedStore: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  userCart: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  orderMinAmount: PropTypes.number.isRequired,
  currency: PropTypes.objectOf(PropTypes.string),
  footerMenuItemsLinksSettings: PropTypes.shape(),
  footerMenuItemsSettings: PropTypes.shape(),
  setOrderMinAmount: PropTypes.func.isRequired,
  headRef: PropTypes.shape({}),
  themeSettings: PropTypes.shape({}),
  activeLoyalty: PropTypes.string
};

Header.defaultProps = {
  userCart: [],
  theme: "dark",
  pathname: null,
  toggleHeaderWidget: null,
  showHeaderWidget: null,
  isStickyCategories: false,
  setStickyCategories: null,
  categoriesPosition: null,
  userToken: null,
  userOrderMethod: null,
  userAddress: null,
  selectedStore: null,
  currency: defaultCurrency,
  footerMenuItemsLinksSettings: {},
  footerMenuItemsSettings: {},
  headRef: null,
  themeSettings: {},
  activeLoyalty: null
};

export const mapStateToProps = state => {
  const {
    showHeaderWidget,
    isStickyCategories,
    categoriesPosition,
    orderMinAmount,
    showDeliveryPickupWidget,
    hideHeaderMenuOnScroll,
    closeTimer
  } = state.elements;
  const {
    userToken,
    userAddress,
    selectedStore,
    userOrderMethod,
    userCart,
    endpoint,
    userInfo,
    userCartId,
    isBetaCustomer,
    userId
  } = state.user;
  const { isDoughProActive } = state.doughPro;
  const { isShippingActive } = state.shipping;
  const { shouldDisplayIntroBanner } = state.braze;
  const { currency } = state.currency;
  const {
    footerMenuItemsSettings,
    footerMenuItemsLinksSettings
  } = state.footerSettings;
  const { order } = state;
  const { themeSettings } = state.theme;
  const { newRedeemableLvlUnlocked, activeLoyalty, newOfferAvailable } = state.loyalty;
  
  return {
    showHeaderWidget,
    isStickyCategories,
    categoriesPosition,
    userAddress,
    selectedStore,
    userOrderMethod,
    userToken,
    userCart,
    userCartId,
    userInfo,
    endpoint,
    isBetaCustomer,
    isDoughProActive,
    isShippingActive,
    userId,
    shouldDisplayIntroBanner,
    orderMinAmount,
    currency,
    footerMenuItemsSettings,
    footerMenuItemsLinksSettings,
    showDeliveryPickupWidget,
    hideHeaderMenuOnScroll,
    order,
    themeSettings,
    closeTimer,
    activeLoyalty,
    newRedeemableLvlUnlocked,
    newOfferAvailable
  };
};

export const mapDispatchToProps = dispatch => ({
  hasActiveOrders: () => dispatch(elementsActions.hasActiveOrders()),
  noActiveOrders: () => dispatch(elementsActions.noActiveOrders()),
  toggleMobileMenu: () => dispatch(elementsActions.toggleMobileMenu()),
  toggleHeaderWidget: () => dispatch(elementsActions.toggleHeaderWidget()),
  setStickyCategories: value =>
    dispatch(elementsActions.setStickyCategories(value)),
  setDoughProSpecialCategory: value =>
    dispatch(elementsActions.setDoughProSpecialCategory(value)),
  setUserId: value => dispatch(userActions.setUserId(value)),
  setModalObject: value => dispatch(elementsActions.setModalObject(value)),
  setBetaCustomer: value => dispatch(userActions.setBetaCustomer(value)),
  setDoughProMember: value => dispatch(userActions.setDoughProMember(value)),
  setOrderMinAmount: value =>
    dispatch(elementsActions.setOrderMinAmount(value)),
  setDeliveryPickupAddressWidget: value =>
    dispatch(elementsActions.setDeliveryPickupAddressWidget(value)),
  setUserOrderMethod: value => dispatch(userActions.setUserOrderMethod(value)),
  setUserAddress: value => dispatch(userActions.setUserAddress(value)),
  setSelectedStore: value => dispatch(userActions.setSelectedStore(value)),
  setCloseTimer: value => dispatch(elementsActions.setCloseTimer(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
