/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import _ from "underscore";
import PropTypes from "prop-types";
// REDUX
import { connect } from "react-redux";
// GRAPHQL
import { v4 as uuidv4 } from 'uuid';
import ReCAPTCHAForm  from "../ReCaptcha/ReCaptcha";
import { checkStoreProductStock } from "../../shared/repos/graphql/product";
import * as orderRepo from "../../shared/repos/graphql/order";
// REDUX
import { store as mainStore } from "../../redux/store";
import * as addressActions from "../../redux/actions/address";
import * as elementsActions from "../../redux/actions/elements";
import * as userActions from "../../redux/actions/user";
import * as countryActions from "../../redux/actions/country";
import { guardOrderChange, guardOrderChangeLocation } from "../../shared/utilities/orders";
import {
  openCartCreateFailModal,
  openOrderUpdateFailModal,
  openUnavailableProductModal
} from "../../shared/utilities/modals";
import {
  setCaptchaToken
} from "../../shared/utilities/captcha";
// COMPONENTS
import FeaturedLocation from "../FeaturedLocation/FeaturedLocation";
import DeliveryLocationsListItem from "../DeliveryLocationsListItem/DeliveryLocationsListItem";
import LocationsListItem from "../LocationsListItem/LocationsListItem";
import removeProductModal from "../RemoveProductModal/RemoveProductModal";
import Loader from "../Loader/Loader";
// HELPERS
import { GeocodeInit, getCurrentPosition } from "../../shared/utilities/geolocation";
import { setSessionToken } from "../../shared/utilities/config";
import * as locationsRepo from "../../shared/repos/graphql/locations";
import * as storeRepo from "../../shared/repos/graphql/store";
import * as userRepo from "../../shared/repos/graphql/user";
import * as tagmanagerEvents from "../../shared/utilities/tagmanagerEvents";

import { storeIds, shippingMethods } from "../../shared/constants/store";
import { orderTypeIds, orderMethods } from "../../shared/constants/order";
import errorCodes from "../../shared/constants/errorCodes";

import styles from "./DeliveryPickUpWidget.module.css";
import endpoints from "../../shared/constants/endpoints";
import {
  getErrorReasons,
  getErrorMessages,
  routeCountryPath,
  clearStorage
} from "../../shared/utilities/common";
import { getSiteSettings } from "../../shared/repos/graphql/siteSetting";
import siteSetting from "../../shared/constants/siteSetting";
import cmsKeys from "../../shared/constants/cms";
import { resetCustomCakeTopperData } from "../../redux/actions/customCakeTopper";
import { resetCurrencyData } from "../../redux/actions/currency";
import captchaAction from "../../shared/constants/captchaAction";
import elementConstants from "../../shared/constants/element";
import httpStatusConstant from "../../shared/constants/httpStatus";

const currentIcon = require("./imgs/current-icon.png");
const searchIcon = require("./imgs/search-icon.png");
const workIcon = require("./imgs/work-icon.png");

const locationIcon = require("./icons/map-pin.svg");
const clearIcon = require("./imgs/clear-icon.png");
const clearDarkIcon = require("./imgs/clear-dark-icon.svg");

const crosshairIcon = require("./icons/crosshair.svg");
const DeliveryIcon = require("./icons/delivery.svg");
const PickupIcon = require("./icons/pickup.svg");
const StorePickupIcon = require("./icons/store-pickup.svg");
const NationwideIcon = require("./icons/nationwide.svg");
const MapLightIcon = require("./imgs/map-light.svg");
// const RightCircleRight = require("./icons/arrow-circle-right.png");

export class DeliveryPickUpWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLocations: false,
      delivery: true,
      inputValue: props.inputValue,
      stores: [],
      myLocation: { lat: null, lng: null, externalId: "" },
      myAddress: null,
      locationLoading: false,
      selectedLocation: {},
      recentAddresses: [],
      recentAddressesLoading: false,
      updateOrderLoading: false,
      postCodeSearchModeData: {
        address: "",
        postalCode: "",
        enabled: false,
        lat: null,
        lng: null,
        customAddress: "",
        externalId: null
      },
      locationDisabled: false,
      locationLoadingText: "Loading your location..",
      storeSelecting: false,
      outOfDeliveryRange: false,
      hasCaptchaError: false,
      showCaptchaChallenge: false,
      skipGenerateNewToken: false,
      clearLocationOnSelect: true //eslint-disable-line
    };

    this.inputDeliveryAddressRef = createRef();
    this.inputPickupAddressRef = createRef();
    this.recaptchaRef = React.createRef();
    this.recaptchaComponentRef = React.createRef();
    this.container = null;
  }

  async componentDidMount() {
    const { addressType, useRecaptcha, setVerificationToken, setVerificationAction } = this.props;
    const { skipGenerateNewToken } = this.state;
    setSessionToken(uuidv4());

    if (useRecaptcha === 1) {
      await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.addressSearching, skipGenerateNewToken);
    }
    await this.recentAddresses();

    this.setState({
      delivery: addressType !== "pickup"
    });

    this.container = document.querySelector("#modalDeliveryPickupWidget")

    this.container.setAttribute("tabindex","0")
    this.container.focus()

    this.container.addEventListener("focusout", this.handleFocusOut)
  }

  componentDidUpdate() {
    if (this.inputDeliveryAddressRef.current) {
      this.inputDeliveryAddressRef.current.focus();
    } else if(this.inputPickupAddressRef.current) {
      this.inputPickupAddressRef.current.focus();
    }
  }

  handleFocusOut = (ev) => {
    if (!this.container.contains(ev.relatedTarget)) this.container.focus();
  };

  componentWillUnmount = () => {
    this.container.removeEventListener("focusout", this.handleFocusOut)
  }

  setStateDispatchers = () => {
    const {
      setModalObject,
      setUserAddress,
      setSelectedStore,
      setUserOrderMethod
    } = this.props;
    setSelectedStore(null);
    setUserAddress(null);
    setUserOrderMethod("");
    setModalObject(null);
  };

  historyBack = () => {
    const { setModalObject } = this.props;
    setModalObject(null);
  };

  createCart = async (storeId, address) => {
    const {
      setUserCartId,
      setUserAddress,
      isModal,
      setModalObject,
      setOrderMinAmount
    } = this.props;
    const { userCartId } = mainStore.getState().user;
    if (userCartId) {
      return;
    }

    const addressComponents = {
      address1: address.address1,
      city: address.city,
      state: address.state,
      postcode: address.postcode,
      lat: address.lat,
      lng: address.lng,
      dormId: address.dormId,
      countryId: address.countryId,
      address2: address.address2
    };

    const data = {
      storeId,
      orderTypeId: orderTypeIds.delivery,
      shippingMethod: shippingMethods.delivery,
      address: addressComponents
    };

    try {
      const response = await orderRepo.createCart(data);
      const { createCart } = response.data;

      setUserCartId(createCart.code);
      setUserAddress(address);
      setOrderMinAmount(createCart.shippingMethodMinAmount);

      if (isModal) {
        setModalObject(null);
      }
    } catch (reason) {
      const message = getErrorMessages(reason, 0);
      const reasonType = getErrorReasons(reason, 0);
      this.handleCartCreateModal(message, reasonType);
    }
  };

  handleCartCreateModal = (message, reason) => {
    const { setModalObject } = this.props;
    const cartCreateError = reason === errorCodes.CART_CREATE_ERROR;
    const updateOrderError = reason === errorCodes.ORDER_UPDATE_ERROR;

    const productAddError = reason === errorCodes.PRODUCT_UPDATE_ERROR;
    if (cartCreateError) {
      setModalObject(
        openCartCreateFailModal(message, this.setStateDispatchers)
      );
    }

    if (updateOrderError) {
      setModalObject(openOrderUpdateFailModal(message));
    }

    if (productAddError) {
      setModalObject(openUnavailableProductModal(this.historyBack, message));
    }
  };

  handleAddressChange = store => {
    const { setSelectedStore } = this.props;
    setSelectedStore(store);
  };

  handlePickupItemSelection = async location => {
    const { saveAddress } = this.props;
    const { address1, externalId, lat, lng } = location;
    saveAddress([location]);

    this.setState({
      myLocation: {
        externalId
      },
      myAddress: address1,
      showLocations: true
    });

    const data = {
      lat,
      lng,
      externalId
    };

    const result = await storeRepo.getStores(data);
    this.getStoresPickupCallback(result.data.storeSearch);
  };

  removeProductModalCallback = async (orderProductIds, store, address) => {
    const { setModalObject, setUserAddress, setUserCart } = this.props;
    const { userCartId } = mainStore.getState().user;

    const removeProductResponse = await orderRepo.removeProducts(
      userCartId,
      orderProductIds
    );

    this.handleAddressChange(store);
    setUserAddress(address);
    setUserCart(removeProductResponse.data.removeOrderItemsFromOrder.items);

    const addressComponents = {
      address1: address.address1,
      city: address.city,
      state: address.state,
      postcode: address.postcode,
      lat: address.lat,
      lng: address.lng,
      dormId: address.dormId,
      countryId: address.countryId
    };

    const data = {
      orderTypeId: orderTypeIds.delivery,
      storeId: store.id,
      address: addressComponents
    };

    await orderRepo.updateOrderLocation(userCartId, data);

    setModalObject(null);
  };

  /**
   * Handles item selection logic
   */
  handleDeliveryItemSelection = async selectedLocation => {
    this.setState({
      onSelectLocationLoader: true
    });

    const { saveAddress, location, addressType } = this.props;
    const {
      lat,
      lng,
      externalId,
      store,
      addressDetails,
      postalCodeValue,
      customAddress
    } = selectedLocation;

    const postCodeSearchModeData = postalCodeValue
      ? {
          address: selectedLocation.address1,
          postalCode: postalCodeValue,
          enabled: true,
          lat,
          lng,
          externalId,
          customAddress: ""
        }
      : {
          address: "",
          postalCode: "",
          enabled: false,
          lat: null,
          lng: null,
          externalId: null,
          customAddress: ""
        };

    if (postCodeSearchModeData.enabled === false) {
      saveAddress([selectedLocation]);
    }

    const state = {
      selectedLocation,
      myLocation: {
        lat,
        lng,
        externalId
      },
      postCodeSearchModeData
    };

    this.setState(state);

    if (store && addressType !== 'shipping') {
      await this.handleInRangeDelivery(addressDetails, store);
      this.handleCloseWidget();
      return;
    }

    const data = {
      lat,
      lng,
      externalId,
      orderTypeId: location.pathname.includes(endpoints.shippingPage)
        ? orderTypeIds.shipping
        : orderTypeIds.delivery,
      customAddress
    };

    const result = await storeRepo.getStores(data);
    if (addressType === "shipping") {
      await this.getShippingAddressTypeCallback(result.data.storeSearch);
    } else {
      await this.getStoresDeliveryCallback(result.data.storeSearch);
    }
  };

  /**
   * Handles delivery type state handling
   * @param {boolean} isDelivery - boolean
   */
  chooseDeliveryType = isDelivery => {
    const { useFullyQualifiedAddressForDelivery } = this.props;
    const postCodeSearchModeData = {
      address: "",
      postalCode: "",
      enabled: false,
      lat: null,
      lng: null,
      externalId: null,
      customAddress: ""
    };
    this.setState({ postCodeSearchModeData, delivery: isDelivery });
    const { inputValue } = this.state;
    if (inputValue.length > 0) {
      this.setState({ locationSearchLoading: true });
      this.handleSearch(
        inputValue,
        isDelivery && useFullyQualifiedAddressForDelivery
      );
    }
  };


  redirectToPdp = (location) => {
    const {redirectUrl, userOrderMethod, redeemables} = this.props;

    const redirectToPdpForDelivery = redeemables.redeemableType && userOrderMethod === orderMethods.delivery
    if (redirectToPdpForDelivery) {
      return routeCountryPath(redirectUrl);
    }

    return location;
  }

  /**
   * Handles input value state setting
   * @param value
   */
  handleInput = value => {
    this.setState({ inputValue: value });
  };

  getStoresPickupCallback = (data) => {
    const { addressUpdated} = this.props;
    const { myAddress } = this.state;
    const { address } = data;

    addressUpdated(address);

    this.setState({
      stores: data.stores,
      inputValue: myAddress
    });
  }

  showCountryChange = (
    addressDetails,
    inRangeDelivery,
    shipping,
    store,
    stores
  ) => {
    const {
      allCountries,
      setSelectedCountry,
      setModalObject,
      isCountrySelectActive,
      history
    } = this.props;

    if (isCountrySelectActive) {
      const addressCountry = allCountries.find(
        country =>
          +country.id === +(store?.countryId || addressDetails.countryId)
      );
      setModalObject({
        title:
          "You are searching for an address in another country, would you like to change your country selection?",
        description:
          "If you remain on the current country website, you may still proceed with ordering, however, all purchases will be completed in the local store currency, and your transaction and your visit to the website will be governed by the local laws.",
        action1: async () => {
          resetCustomCakeTopperData();
          resetCurrencyData();
          userActions.resetUserData();
          clearStorage();
          await setSelectedCountry(addressCountry);
          if (inRangeDelivery === "shipping") {
            shipping();
            window.location.reload();
          } else if (inRangeDelivery) {
            await this.handleInRangeDelivery(addressDetails, store);
            setModalObject(null);
            window.location.reload();
          } else {
            history.push({
              pathname: this.redirectToPdp(routeCountryPath(endpoints.locations)),
              state: {
                searchLocation: {
                  lat: addressDetails.lat,
                  lng: addressDetails.lng
                },
                selectedAddress: addressDetails.myAddress,
                stores
              }
            });
            setModalObject(null);
          }
        },
        action2: async () => {
          if (inRangeDelivery === "shipping") {
            shipping();
          } else if (inRangeDelivery) {
            await this.handleInRangeDelivery(addressDetails, store);
            setModalObject(null);
          } else {
            history.push({
              pathname: this.redirectToPdp(routeCountryPath(endpoints.locations)),
              state: {
                searchLocation: {
                  lat: addressDetails.lat,
                  lng: addressDetails.lng
                },
                selectedAddress: addressDetails.myAddress,
                stores
              }
            });
            setModalObject(null);
          }
        },
        action1Label: "Yes",
        action2Label: "No"
      });
    }
    return true;
  };

  handleInRangeDelivery = async (addressDetails, store) => {
    const {
      setSelectedStore,
      setUserOrderMethod,
      setModalObject,
      setUserAddress,
      history,
      isModal,
      setOrderMinAmount,
      setUserCart,
      productId,
      location
    } = this.props;

    const { userCartId } = mainStore.getState().user;
    await setUserOrderMethod("DELIVERY");
    const addressComponents = {
      address1: addressDetails.address1,
      city: addressDetails.city,
      state: addressDetails.state,
      postcode: addressDetails.postcode,
      lat: addressDetails.lat,
      lng: addressDetails.lng,
      dormId: addressDetails.dormId,
      countryId: addressDetails.countryId,
      address2: addressDetails.address2
    };

    await this.createCart(store.id, addressComponents);

    if (userCartId) {
      const response = await checkStoreProductStock(userCartId, store.id);

      const oosIndicator = 1;
      const oosProducts = response.data.storeProductStock.filter(
        item => parseInt(item.oos, 10) === oosIndicator
      );

      if (oosProducts.length) {
        const orderProductIds = _.pluck(oosProducts, "orderProductId");

        return removeProductModal(setModalObject, store, oosProducts, () => {
          this.removeProductModalCallback(
            orderProductIds,
            store,
            addressDetails
          );
        });
      }

      const updateOrderData = {
        orderTypeId: orderTypeIds.delivery,
        storeId: store.id,
        address: addressComponents
      };

      try {
        const updateOrderLocation = await orderRepo.updateOrderLocation(
          userCartId,
          updateOrderData
        );
        setOrderMinAmount(
          updateOrderLocation.data.updateOrder.shippingMethodMinAmount
        );
        setUserAddress(addressDetails);
        setSelectedStore(store);
      } catch (reason) {
        const message = getErrorMessages(reason, 0);
        const reasonType = getErrorReasons(reason, 0);
        return this.handleCartCreateModal(message, reasonType);
      }
    } else {
      setSelectedStore(store);
      const { user } = mainStore.getState();

      try {
        if (productId) {
          const orderData = await orderRepo.addProductToOrder(user.userCartId, {product: productId});

          const localStorageCart = orderData.data?.addProductToOrderV2?.items.map((item) => {
              return item;
          });

          setUserCart(localStorageCart);
        }
      } catch (reason) {
        const message = [
          "This item is out of stock. Please select another product from our menu!"
        ];
        const reasonType = getErrorReasons(reason, 0);
        return this.handleCartCreateModal(message, reasonType);
      }
    }

    if (!isModal) {
      if (!location?.pathname?.includes("/products")) {
        history.push(this.redirectToPdp(routeCountryPath(endpoints.getMenuUrl(store))));
      }
    }

    return null;
  };

  getSiteSetting = () =>
    new Promise((resolve, reject) => {
      return getSiteSettings([siteSetting.SHIPPING_ACTIVE], true)
        .then(response => {
          if (!response || !response.data || !response.data.siteSettings) {
            reject(response);
            return;
          }
          resolve(response);
        })
        .catch(reject);
    });

  enablePostcodeSearchMode = () => {
    this.setState({
      inputValue: "",
      locationSearchData: [],
      locationSearchLoading: false,
      locationLoading: false,
      onSelectLocationLoader: false,
    });
  };

  getShippingAddressTypeCallback = async data => {
    const {
      isShippingActive,
      setAutocompleteSessionToken,
    } = this.props;

    const { selectedLocation, inputValue, postCodeSearchModeData } = this.state;
    const { addressDetails }  = selectedLocation;
    const { stores } = data;
    let { address } = data;
    address = address || addressDetails;
    setAutocompleteSessionToken(null);

    // eslint-disable-next-line
    let shippingActiveOverride = isShippingActive;
    if (address) {
      const resp = await this.getSiteSetting();

      const allSettings = resp.data.siteSettings;

      let searchSetting = _.find(allSettings, {
        name: siteSetting.SHIPPING_ACTIVE,
        country_id: address.countryId
      });

      if (searchSetting === undefined) {
        searchSetting = _.find(allSettings, {
          name: siteSetting.SHIPPING_ACTIVE,
          country_id: null
        });
      }

      shippingActiveOverride = searchSetting.value === "1";
    }

    if (postCodeSearchModeData.enabled === true) {
      this.enablePostcodeSearchMode();
    } else if (shippingActiveOverride) {
      tagmanagerEvents.outOfDeliveryRange(inputValue, address);
      this.setState({
        locationSearchLoading: false,
        locationLoading: false,
        clearLocationOnSelect: true, // eslint-disable-line
        onSelectLocationLoader: false,
        inputValue: address ? address.address1 : '',
        stores,
        address,
        storeInDeliveryRangeIndex: null,
      });
      this.handleShippingMenu();
    }
  };

  getStoresDeliveryCallback = async data => {
    const {
      selectedCountry,
      isCountrySelectActive,
      history,
      location,
      setAutocompleteSessionToken,
    } = this.props;

    const { selectedLocation, inputValue, postCodeSearchModeData } = this.state;
    const { addressDetails }  = selectedLocation;
    const { stores } = data;
    let { address } = data;
    address = address || addressDetails;
    setAutocompleteSessionToken(null);
    const storeInDeliveryRangeIndex = data.stores.findIndex(
      el => el.inDeliveryRange === true
    );

    this.setState({
      outOfDeliveryRange: false
    });
    if (postCodeSearchModeData.enabled === true) {
      this.enablePostcodeSearchMode();
    } else if (storeInDeliveryRangeIndex !== -1) {
      const store = stores[storeInDeliveryRangeIndex];
      this.setState({
        stores,
        address,
        storeInDeliveryRangeIndex: store
      });
      if (
        isCountrySelectActive === true &&
        +(store?.countryId || address.countryId) !== +selectedCountry.id
      ) {
        this.showCountryChange(address, true, "", store, stores);
        return
      }
        await this.handleInRangeDelivery(address, store);

      if (!location?.pathname?.includes("/products")) {
        history.push(this.redirectToPdp(routeCountryPath(endpoints.getMenuUrl(store))));
      }
      this.handleCloseWidget();
    } else {
      tagmanagerEvents.outOfDeliveryRange(inputValue, address);
      const store = stores[storeInDeliveryRangeIndex];
      this.setState({
        locationSearchLoading: false,
        locationLoading: false,
        outOfDeliveryRange: true,
        clearLocationOnSelect: true, // eslint-disable-line
        onSelectLocationLoader: false,
        inputValue: address.address1,
        stores,
        address,
        storeInDeliveryRangeIndex: store,
        storeSelecting: false,
        recentAddressesLoading: false,
      });
    }
  };

  handleShippingMenu = () => {
    const { isCountrySelectActive, selectedCountry  } = this.props;
    const {address, storeInDeliveryRangeIndex, stores, selectedLocation} = this.state;

    this.setState({onSelectLocationLoader: true})

    if (isCountrySelectActive === true && +(storeInDeliveryRangeIndex?.countryId || address?.countryId) !== +selectedCountry.id) {
      return this.showCountryChange(
        address,
        "shipping",
        this.handleSelectShipping.bind(this, selectedLocation),
        storeInDeliveryRangeIndex,
        stores
      );
    }
    return this.handleSelectShipping(selectedLocation)
  };

  viewPickUpMenu = () => {
    const { history, isCountrySelectActive, selectedCountry } = this.props;
    const { myAddress, stores, myLocation, address, storeInDeliveryRangeIndex } = this.state;

    this.handleCloseWidget();
    if (isCountrySelectActive === true && +(storeInDeliveryRangeIndex?.countryId || address?.countryId) !== +selectedCountry.id) {
      this.showCountryChange(address, false, '', storeInDeliveryRangeIndex, stores);
    } else {
      history.push({
        pathname: this.redirectToPdp(routeCountryPath(endpoints.locations)),
        state: {
          searchLocation: {
            lat: stores[0].lat,
            lng: stores[0].lng,
            externalId: myLocation.externalId
          },
          selectedAddress: myAddress,
          stores
        }
      });
    }
  };

  outOfDeliveryRangeWidget = () => {
    const { stores } = this.state;
    const { userToken, isShippingActive } = this.props;

    const shippingButtonComponent = isShippingActive ? (
      <div
        className="w-full md:w-[274px] h-[220px] px-2 py-4 rounded-[20px]
      flex flex-col gap-3
      border border-borderLight border-solid text-center mx-auto text-dark"
      >
        <img className="mx-auto" src={NationwideIcon} alt="nationwide" />
        <div className="font-filsonProRegular">
          <p className="text-base font-bold">Nationwide shipping</p>
          <p>
            <span className="w-full text-darkDry text-sm leading-4">
              Shipped in 2-3 days
            </span>
          </p>
        </div>
        <button
          type="button"
          id="nationwideBtn"
          className={`
      flex flex-row justify-center items-center
      w-[118px] px-2 md:py-3 gap-2 h-11 bg-dark
      rounded-[40px] text-light
      font-filsonProBold mx-auto
      `}
          onClick={this.handleShippingMenu}
        >
          View menu
        </button>
      </div>
    ) : (
      ""
    );

    return (
      <div className="w-full" id="notInRange">
        <div className="w-full">
          {/* eslint-disable-next-line */}
          {!isShippingActive ? (
            <p className="text-sm font-[425] text-dark font-filsonProRegular">
              Unfortunately, this address is outside of delivery range.
            </p>
          ) : (
            <p className="text-sm font-[425] text-dark font-filsonProRegular">
              Good news! Even though your address is out of range, you can still
              get our cookies at a local store or with a courier.
            </p>
          )}
        </div>
        <div className="flex w-full mt-3 flex-col md:flex-row items-center gap-2">
          {stores.length > 0 ? (
            <div
              className="w-full md:w-[274px] h-[220px] px-2 py-4 rounded-[20px]
            flex flex-col gap-3
            border border-borderLight border-solid text-center mx-auto text-dark "
            >
              <img
                className="mx-auto"
                src={StorePickupIcon}
                alt="store pickup"
              />

              <div className="font-filsonProRegular">
                <p className="text-base font-bold">Store pickup</p>
                <p>
                  <span className="text-sm leading-4 text-dark">
                    Fresh warm cookies, ready for pickup
                  </span>
                  <span className="text-sm leading-4 text-dark">
                    {stores[0].address}
                    {' '}
-
                    {stores[0].distanceToStore}
                  </span>
                </p>
              </div>

              <button
                type="button"
                id="pickupBtn"
                onClick={() => {
                  this.viewPickUpMenu();
                }}
                className={`
              flex flex-row justify-center items-center
              w-[118px] px-2 md:py-3 gap-2 h-11 bg-dark
              rounded-[40px] text-light
              font-filsonProBold mx-auto
              `}
              >
                View menu
              </button>
            </div>
          ) : null}

          {shippingButtonComponent}
        </div>
        {userToken && (
          <div className="w-full text-center my-4 ">
            <p className="text-sm font-[425] text-button font-filsonProRegular px-4 lg:px-0 py-8 bg-red-200 w-72 lg:w-full mx-auto rounded-xl">
              Reward Points can only be redeemed through Delivery or Pickup.
            </p>
          </div>
        )}
      </div>
    );
  };

  handleSelectShipping = async selectedLocation => {
    const {
      setUserCartId,
      history,
      setSelectedStore,
      setModalObject,
      setUserOrderMethod,
      setUserAddress,
      userCartId
    } = this.props;
    const { updateOrderLoading } = this.state;
    if (updateOrderLoading) {
      return;
    }
    this.setState({
      updateOrderLoading: true
    });

    const orderData = {
      orderTypeId: orderTypeIds.shipping,
      storeId: storeIds.shipping,
      shippingMethod: shippingMethods.shipping
    };

    const { address } = this.state;
    const selectedLocationClone = { ...selectedLocation };
    if (!selectedLocation.externalId && address.address1 === selectedLocation.address1 && address.lat && address.lng) {
      const geocodeResponse = await GeocodeInit.fromLatLng(address.lat, address.lng);
      if (geocodeResponse && geocodeResponse.results.length > 0) {
        /* eslint-disable camelcase */
        const { place_id } = geocodeResponse.results[0];
        selectedLocationClone.externalId = place_id;
      }
    }
    try {
      if (userCartId) {
        await orderRepo.updateOrderLocation(userCartId, orderData);
        await orderRepo.setShippingAddress(
          userCartId,
          selectedLocationClone.externalId
        );
      } else {
        const responseCreatCart = await orderRepo.createCart(orderData);
        const orderCode = responseCreatCart.data.createCart.code;
        setUserCartId(orderCode);
        await orderRepo.setShippingAddress(
          orderCode,
          selectedLocationClone.externalId
        );
      }
      setSelectedStore({
        id: storeIds.shipping,
        name: "Gifts",
        slug: "gifts"
      });
      setUserOrderMethod("SHIPPING");
      setUserAddress(selectedLocationClone);
      this.setState({
        updateOrderLoading: false
      });
      history.push(routeCountryPath(endpoints.shippingPage));
      setModalObject(null);
    } catch (exception) {
      setModalObject(null);
    }
    this.setState({onSelectLocationLoader: false})
    this.handleCloseWidget();
  };

  handleAddressSearch = (value, fullQualified) => {
    this.setState({ inputValue: value, outOfDeliveryRange: false });
    this.handleSearch(value, fullQualified);
  };

  // eslint-disable-next-line react/sort-comp
  handleSearch = _.debounce(async (value, fullyQualified = false) => {
    const { userOrderMethod, useRecaptcha, setVerificationToken, setVerificationAction, setVerificationProvider } = this.props;
    const showDorms = userOrderMethod !== orderMethods.shipping ?? false;
    const { myLocation, postCodeSearchModeData, skipGenerateNewToken } = this.state;
    const searchValue = postCodeSearchModeData.enabled
      ? `${postCodeSearchModeData.address}, ${value}`
      : value;
    postCodeSearchModeData.customAddress = value;
    this.setState({
      postCodeSearchModeData,
      inputValue: value
    });
    if (searchValue.length < 3) {
      return;
    }
    if (useRecaptcha === 1) {
      await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.addressSearching, skipGenerateNewToken);
    }
    this.setState({
      locationSearchLoading: true,
      showLocations: false,
    });

    try {
      const { lat, lng } = myLocation;
      let filter = null;
      let fullQualifiedValue = fullyQualified;
      if (postCodeSearchModeData.enabled) {
        filter = postCodeSearchModeData.postalCode;
        fullQualifiedValue = true;
      }
      const result = await locationsRepo.getLocations(
        searchValue,
        lat,
        lng,
        fullQualifiedValue,
        showDorms,
        filter
      );
      const locations = _.filter(result.data.locationSearch, item => {
        return !(
          postCodeSearchModeData.enabled &&
          item.postalCodeValue &&
          item.postalCodeValue === postCodeSearchModeData.postalCode
        );
      });
      this.setState({
        locationSearchData: locations
      });
      setVerificationProvider('');
    } catch (e) {
      this.setState({
        locationSearchError: e.message,
      });
      const error = e.message;
      const hasUnauthorized = (error) ? error.indexOf(httpStatusConstant.unauthorised) !== -1 : false;
      if (e.message) {
        this.setState({
          hasCaptchaError: hasUnauthorized,
          showCaptchaChallenge: hasUnauthorized,
          locationSearchData: []
        });
      } else {
        this.setState({
          showCaptchaChallenge: false,
          hasCaptchaError: false,
          skipGenerateNewToken: false,
        });
      }
    }

    this.setState({
      locationSearchLoading: false
    });
  }, 500);

  handleRecentAddressSelect = async recentAddress => {
    const { address } = recentAddress;
    const { addressType } = this.props;

    this.setState({
      recentAddressesLoading: true,
    });

    await this.setState({
        myLocation: {},
        myAddress: null,
        selectedLocation: {
            id: null,
            address1: address.address1,
            externalId: '',
            __typename: 'Location'
        },
        storeSelecting: true
    });

    const addressComponents = {
      address1: address.address1,
      city: address.city,
      state: address.state,
      postcode: address.postcode,
      lat: address.lat,
      lng: address.lng,
      dormId: address.dormId,
      countryId: address.countryId
    };

    const data = {
      lat: address.lat,
      lng: address.lng,
      externalId: null,
      orderTypeId: orderTypeIds.delivery
    };
    const result = await storeRepo.getStores(data);

    const deliveryData = {
      stores: result.data.storeSearch.stores,
      address: addressComponents
    };
    if (addressType === "shipping") {
      await this.getShippingAddressTypeCallback(deliveryData);
    } else {
      await this.getStoresDeliveryCallback(deliveryData);
    }
  };

  renderErrorUI = () => (
    <div className={styles.errorContainer}>
      <p>Oops, something went wrong</p>
      <p>There was a network error, try refreshing your browser.</p>
      <span onClick={() => window.location.reload()}>Reload Page</span>
    </div>
  );

  renderCaptchaChallenge = () => {
    const { showCaptchaChallenge } = this.state;
    const { useRecaptcha } = this.props;
    return (
      (showCaptchaChallenge && useRecaptcha) ? (
        <div className={styles.errorContainer}>
          <ReCAPTCHAForm
            ref={this.recaptchaComponentRef}
            setToken={this.onVerifyCaptcha}
          />
        </div>
      ) : <div className="testting" />
    )
  };

  onVerifyCaptcha = (token) => {
    if (token) {
      this.setState({
        showCaptchaChallenge: false,
        skipGenerateNewToken: true
      });

      const { setVerificationToken, setVerificationProvider } = this.props;
      const { inputValue } = this.state;
      setVerificationToken(token);
      setVerificationProvider(elementConstants.challengeRecaptcha)

      this.handleSearch(inputValue);
    }
  }

  /**
   * Clears delivery input
   */
  clearDeliveryInput = () => {
    this.setState({inputValue: "", outOfDeliveryRange: false});
  };

  /**
   * Set current location.
   *
   * @param {string} myAddress
   * @param {object} myLocation
   *
   * @author Elizabeth Clouser <elizabeth@somepage.com>
   */
  handleCurrentLocationSelect = async (myAddress, myLocation) => {
    const { delivery } = this.state;
    const { lat, lng, externalId } = myLocation;
    const { addressType } = this.props;

    const loc = {
      id: null,
      address1: myAddress,
      externalId: externalId || "",
      __typename: "Location"
    };

    this.setState({
      locationLoading: true,
      inputValue: myAddress
    });

    this.setState({
      selectedLocation: loc,
      myAddress,
      myLocation: {
        lat,
        lng,
        externalId
      }
    });

    const data = {
      lat,
      lng,
      externalId,
      orderTypeId: delivery ? orderTypeIds.delivery : orderTypeIds.pickup
    };
    const result = await storeRepo.getStores(data);

    if (addressType === "shipping") {
      await this.getShippingAddressTypeCallback(result.data.storeSearch);
    } else if (delivery) {
      await this.getStoresDeliveryCallback(result.data.storeSearch);
    } else {
      await this.getStoresPickupCallback(result.data.storeSearch);
    }
  };

  recentAddresses = async () => {
    const { userToken } = this.props;
    if (!userToken) {
      return;
    }

    this.setState({
      recentAddressesLoading: true
    });

    const response = await userRepo.recentAddresses();

    const me = response?.data?.me;

    this.setState({
      recentAddressesLoading: false,
      recentAddresses: me?.recentAddresses,
      postCodeSearchModeData: {
        address: "",
        postalCode: "",
        enabled: false,
        lat: null,
        lng: null,
        externalId: null,
        customAddress: ""
      }
    });
  };

  selectDeliveryLocation = location => {
    const { isModal, userAddress, userCart } = this.props;
    const selectedAddress = userAddress ? userAddress.address1 : "";
    let showOrderGuardModal = userCart.length > 0 && !location.postalCodeValue;
    showOrderGuardModal =
      selectedAddress === location.address1 ? false : showOrderGuardModal;
    if (isModal) {
      return this.handleDeliveryItemSelection(location, false);
    }

    return guardOrderChangeLocation(
      showOrderGuardModal,
      this.handleDeliveryItemSelection,
      location,
      false
    );
  };

  selectCustomLocation = () => {
    const { postCodeSearchModeData } = this.state;
    return this.selectDeliveryLocation({
      lat: postCodeSearchModeData.lat,
      lng: postCodeSearchModeData.lng,
      externalId: postCodeSearchModeData.externalId,
      postalCodeValue: null,
      store: null,
      addressDetails: null,
      customAddress: postCodeSearchModeData.customAddress.trim()
    });
  };

  /**
   * Renders delivery section
   */
  renderDeliverySection = () => {
    const {
      locationLoading,
      inputValue,
      myLocation,
      myAddress,
      locationSet,
      locationSearchData,
      locationSearchError,
      locationSearchLoading,
      recentAddresses,
      recentAddressesLoading,
      postCodeSearchModeData,
      locationLoadingText,
      storeSelecting,
      locationDisabled,
      outOfDeliveryRange,
      onSelectLocationLoader,
      hasCaptchaError
    } = this.state;

    const { userToken, isModal, userCart, cmsContent, useFullyQualifiedAddressForDelivery, addressType } = this.props;

    if (!myAddress && !locationLoading && !locationSet && !locationDisabled) {
      this.handleCurrentPosition(isModal)
    }
    const showOrderGuardModal = userCart.length > 0;
    const showFeaturedLocation = myLocation.lat && myLocation.lng && myAddress;
    const showUserLocations =
      inputValue.length === 0 &&
      !isModal &&
      postCodeSearchModeData.enabled === false;
    const deliveryAddressPlaceHolder =
      _.find(cmsContent, {
        key: cmsKeys.placeholders.enterDeliveryAddress
      })?.value || "Enter Delivery Address";
    const shippingAddressPlaceholder =
      _.find(cmsContent, {
        key: cmsKeys.placeholders.enterShippingAddress
      })?.value || "Enter Shipping Address";
    const isShipping = addressType === "shipping";
    const addressPlaceholder = isShipping
      ? shippingAddressPlaceholder
      : deliveryAddressPlaceHolder;

    return (
      <div className={`${styles.deliveryContainer} w-full`}>
        {postCodeSearchModeData.enabled ? (
          <div className={styles.deliveryPostCodeSearchMessage}>
            <div className={styles.postcodeMessageTitle}>
              {postCodeSearchModeData.address}
            </div>
            <div className={styles.postcodeMessageSubtitle}>
              Please provide your street address:
            </div>
          </div>
        ) : null}
        <div
          className={`${styles.deliveryInputContainer} border-solid
          bg-white border-lightDry border-[1px]`}
        >
          <img alt="location-icon" src={!isModal ? locationIcon : searchIcon} />
          <input
            id="delivery-widget-enter-delivery-address"
            name="input_delivery_address"
            onChange={e =>
              this.handleAddressSearch(
                e.target.value,
                useFullyQualifiedAddressForDelivery
              )}
            placeholder={
              postCodeSearchModeData.enabled
                ? "Enter Street Address"
                : addressPlaceholder
            }
            value={inputValue}
            className="font-filsonProRegular bg-transparent outline-none text-dark inputModeOverride"
            ref={this.inputDeliveryAddressRef}
          />
          {inputValue.length > 0 && !locationSearchLoading ? (
            <img
              onClick={this.clearDeliveryInput}
              alt="clear-icon"
              src={clearIcon}
            />
          ) : null}
          {locationSearchLoading || locationLoading ? (
            <span>
              <Loader height={25} id="location_loader" />
            </span>
          ) : null}
        </div>
        {hasCaptchaError ? this.renderCaptchaChallenge() : (locationSearchError) ? this.renderErrorUI() : null}

        {showUserLocations ? (
          <div>
            {showFeaturedLocation ? (
              <FeaturedLocation
                onClick={() =>
                  guardOrderChange(
                    showOrderGuardModal,
                    this.handleCurrentLocationSelect,
                    myAddress,
                    myLocation
                  )}
                icon={currentIcon}
                label="Use my current location"
                value={myAddress}
              />
            ) : null}
            {userToken ? (
              <div
                className={`${styles.savedLocation} text-dark border-t-2 border-dotted border-darkElevationPrimary`}
              >
                <p className="text-darkElevationPrimary font-filsonProBold text-lg">Recent Addresses</p>
                {recentAddressesLoading ? <Loader height={25} /> : null}
                {recentAddresses.length
                  ? recentAddresses.map(recentAddress => {
                    const addressTmp = [];
                    addressTmp.push(recentAddress.address.address1);
                    addressTmp.push(recentAddress.address.city);
                    if (recentAddress.address.state) {
                      addressTmp.push(recentAddress.address.state);
                    }
                    addressTmp.push(recentAddress.address.postcode);
                    if (recentAddress.address.country) {
                      addressTmp.push(recentAddress.address.country);
                    }
                    const address = addressTmp.join(", ");

                    return (
                      <FeaturedLocation
                        onClick={() =>
                          guardOrderChange(
                            showOrderGuardModal,
                            this.handleRecentAddressSelect,
                            recentAddress
                          )}
                        icon={workIcon}
                        value={address}
                        label=""
                      />
                    );
                  })
                  : null}
              </div>
            ) : null}
          </div>
        ) : null}

        {(!myAddress && !isModal && storeSelecting) || onSelectLocationLoader ? (
          <div className={styles.locationLoaderContainer}>
            <div className={styles.locationLoader}>
              <Loader height={25} />
              <p>{locationLoadingText}</p>
            </div>
          </div>
        ) : null}

        {inputValue.length < 1 ? null : !locationSearchLoading &&
        locationSearchData &&
        !onSelectLocationLoader &&
        !outOfDeliveryRange &&
        locationSearchData.length > 0 ? (
          <div>
            {locationSearchData.map(location => (
              <DeliveryLocationsListItem
                key={location.address1}
                onClick={() => this.selectDeliveryLocation(location)}
                coords={{lat: location.lat, lng: location.lng}}
                icon={locationIcon}
                address1={
                  location.postalCodeValue
                    ? `${location.postalCodeValue} (click to enter address), ${location.address1}`
                    : location.address1
                }
                addressDetails={location.addressDetails}
              />
            ))}
          </div>
        ) : null}
        {postCodeSearchModeData.enabled &&
        postCodeSearchModeData.customAddress &&
        !locationSearchLoading ? (
          <div>
            <DeliveryLocationsListItem
              key="postcodeCustom"
              onClick={() => this.selectCustomLocation()}
              coords={{
                lat: postCodeSearchModeData.lat,
                lng: postCodeSearchModeData.lng
              }}
              icon={locationIcon}
              address1={`${postCodeSearchModeData.customAddress}, ${postCodeSearchModeData.address}`}
              addressDetails={null}
            />
          </div>
        ) : null}

        {outOfDeliveryRange && !onSelectLocationLoader ? this.outOfDeliveryRangeWidget() : null}
      </div>
    );
  };

  handleCurrentPosition = (isModal, callback) => {
    this.setState({
      locationLoading: true,
        storeSelecting: true
    });

    getCurrentPosition(response => {
      if (!response) {
        this.setState({
          locationLoading: false,
          locationDisabled: true,
          storeSelecting: false
        });

        return null;
      }

      const address = isModal ? null : response.results[0].formatted_address;
      const { coords } = response.position;
      /* eslint-disable camelcase */
      const { place_id } = response.results[0];

      this.setState({
        myAddress: address,
        myLocation: {
          lat: coords.latitude,
          lng: coords.longitude,
          externalId: place_id
        },
        locationLoading: false,
        locationSet: true,
        storeSelecting: false
      });

      if (callback) {
        return callback();
      }
      return null;
    });
  };

  /**
   * Handles showing all locations
   */
  handleAllLocations = () => {
    const { setModalObject } = this.props;
    setModalObject(null);
  };

  /**
   * The store pickup open status.
   *
   * @author Elizabeth Clouser <elizabeth@somepage.com>
   */
  locationIsOpen = store => {
    return store.isPickupOpen;
  };

  toLocationsPage = () => {
    const { setModalObject, history } = this.props;
    setModalObject(null);
    history.push(this.redirectToPdp(routeCountryPath(endpoints.locations)));
    this.handleCloseWidget();
  };

  handleCloseWidget = () => {
    const { onClose, hideHeaderWidget, setModalObject } = this.props;
    onClose ? onClose() : hideHeaderWidget(); // eslint-disable-line

    setModalObject(null);
  };

  storeIsselected = (store) => {
    const { selectedStore } = this.props;
    return selectedStore && selectedStore.id === store.id
  }

  /**
   * Renders pickup section
   */
  renderPickUpSection = () => {
    const {
      inputValue,
      myAddress,
      showLocations,
      delivery,
      locationLoading,
      stores,
      locationSearchLoading,
      locationSearchData,
      locationSearchError,
      myLocation,
      hasCaptchaError
    } = this.state;
    const { isModal, userCart,history, showPickUpButton, clickPickUpButton, selectedPopupStore} = this.props;
    const showUserLocations = inputValue.length === 0 && !isModal;
    const showFeaturedLocation = myLocation.lat && myLocation.lng && myAddress;
    const showOrderGuardModal = userCart.length > 0;

    return (
      <div className={`${styles.pickUpContainer} w-full`}>
        {!showPickUpButton ? (
          <div className={`${styles.pickUpInputContainer} w-full`}>
            <div
              className={`border-solid
          bg-white border-lightDry border-[1px] w-full`}
            >
              <img alt="search-icon" src={searchIcon} />
              <input
                id="delivery-widget-enter-pickup-address"
                name="input_pickup_address"
                className="font-filsonProRegular bg-transparent outline-none text-dark inputModeOverride"
                onChange={e => this.handleAddressSearch(e.target.value)}
                value={inputValue}
                placeholder="Find bakery near you"
                ref={this.inputPickupAddressRef}
              />
              {/* {inputValue.length > 0 && !locationSearchLoading ? (
              <img
                onClick={this.clearDeliveryInput}
                alt="clear-icon"
                src={clearIcon}
              />
            ) : null} */}
              {/* {locationSearchLoading ? (
              <span>
                <Loader height={25} />
              </span>
            ) : null} */}
              {/* span here needs styling */}
              {locationLoading || locationSearchLoading ? (
                <span className={`${styles.loaderWrapper}`}>
                  <Loader height={25} />
                </span>
              ) : (
                <div
                  className=" hidden md:flex items-center gap-2 cursor-pointer
                 w-[220px] h-8 text-white
               bg-dark rounded-[40px] px-4 py-2
               text-sm leading-4 font-bold font-filsonProRegular
               "
                  onClick={this.toLocationsPage}
                >
                  <img
                    className={`${styles.pickupLocationIcon}`}
                    alt="location-icon"
                    src={MapLightIcon}
                  />

                  <p>View on map</p>
                </div>
              )}
            </div>
          </div>
        ): (
          <div>
            <div className="flex flex-row justify-center">
              <p className="w-full py-2 text-center text-darkElevationPrimary font-bold font-filsonProRegular">
                {selectedPopupStore?.name}
              </p>
            </div>
            <div className="flex flex-row justify-center">
              <p className="w-full py-2 text-center text-darkElevationPrimary">
                {selectedPopupStore?.address}
              </p>
            </div>
            <div className="flex flex-row justify-center py-2">
              <div
                className="md:flex items-center gap-2 cursor-pointer
              h-10 text-white
              flex-grow-0
            bg-dark rounded-[20px] px-4 py-2
            text-sm leading-4 font-bold font-filsonProRegular
            "
                onClick={clickPickUpButton}
              >
                <p className="text-center">View Pickup Menu</p>
              </div>
            </div>
          </div>
)}

        {/* <Link onClick={this.handleAllLocations} to={endpoints.locations}>
          View all locations &gt;
          {' '}
        </Link> */}

        {hasCaptchaError ? this.renderCaptchaChallenge() : (locationSearchError) ? this.renderErrorUI() : null}

        {showUserLocations ? (
          <>
            {showFeaturedLocation ? (
              <FeaturedLocation
                onClick={() =>
                  guardOrderChange(
                    showOrderGuardModal,
                    this.handleCurrentLocationSelect,
                    myAddress,
                    myLocation
                  )}
                icon={crosshairIcon}
                label="Use my current location"
                value={myAddress}
              />
            ) : null}
          </>
        ) : null}

        {/* HERE */}
        {inputValue.length < 1 ? null : !locationSearchLoading &&
        !showLocations &&
        !delivery &&
        locationSearchData &&
        locationSearchData.length > 0 ? (
          <div className={styles.locationListContainer}>
            {locationSearchData.map(location => (
              <DeliveryLocationsListItem
                isSelected={isModal && myAddress === location.address1}
                key={location.address1}
                onClick={() => this.handlePickupItemSelection(location)}
                coords={{lat: location.lat, lng: location.lng}}
                icon={locationIcon}
                address1={location.address1}
                addressDetails={location.addressDetails}
              />
            ))}
          </div>
        ) : null}

        {!locationSearchLoading && showLocations ? (
          <div className={styles.locationListContainer}>
            {stores.map(store => (
              <LocationsListItem
                key={store.id}
                to={endpoints.getMenuUrl(store)}
                id={store.id}
                isOpen={this.locationIsOpen(store)}
                closePopup={()=>this.handleCloseWidget()}
                name={store.name}
                store={store}
                mailingAddress={store.mailingAddress}
                city={store.city}
                state={store.state}
                zip={store.zip}
                changeAddress={this.storeIsselected(store)}
                distanceFrom={store.distanceToStore}
                history={history}
                address={store.address}
                telephone={store.phone}
                isDeliveryInRange={store.inDeliveryRange}
              />
            ))}
          </div>
        ) : null}

      </div>
    );
  };

  deliveryPickUpWidgetContainer = () => {
    const { delivery } = this.state;
    const { addressType, disableCloseButton } = this.props;
    const disableCssClass =
      " opacity-60 bg-opacity-60 border-opacity-60 text-opacity-60";
    const hoverCssClass =
      " hover:opacity-100 hover:bg-opacity-100 hover:border-opacity-100 hover:text-opacity-100";

    return (
      <div
        className={`${styles.widgetWrapper}
      fixed inset-0 bg-opacity-25
      backdrop-blur-sm flex justify-center items-center
      zIndex shadow-[0_0_15px_6px_rgba(0,0,0,0.35)]
      bg-darkBlack
      `}
      >
        <div
          id="modalDeliveryPickupWidget"
          className={`${styles.widgetContainer}

          md:shadow-modalShadow md:dark:shadow-modalDarkShadow
          bg-white w-[600px] flex flex-col p-[20px] md:rounded-[40px]
           h-full md:h-auto md:min-h-[289px] outline-none
        `}
        >
          {disableCloseButton ? null : (
            <div
              className="w-full flex md:hidden mb-8 justify-end"
              id="closeWidget"
              onClick={() => {
              this.handleCloseWidget(); // eslint-disable-line
            }}
            >
              <img
                src={clearDarkIcon}
                alt=""
                className="w-6 h-6 object-contain"
              />
            </div>
 )}
          {/* WIDGET HEADER */}
          <div className="relative flex items-center flex-col gap-4 w-full mx-auto">
            { addressType === "shipping"
              ? <div className={`${styles.widgetType} flex gap-4 w-full md:w-96 px-4 lg:px-0  item-center`}><p /></div>
              :
              (
                <div
                  className={`${styles.widgetType} flex gap-4 w-full md:w-96 px-4 lg:px-0  item-center`}
                >
                  <p
                    id="deliveyWidget"
                    onClick={() => this.chooseDeliveryType(true)}
                    className={`${delivery ? styles.chosenType : disableCssClass}
                  flex flex-row justify-center items-center
                  md:w-[183px] w-full px-4 py-1 gap-2 h-11 bg-transparent border
                  border-borderLight  border-solid rounded-[54px]
                  text-dark
                  font-filsonProBold cursor-pointer
                  ${hoverCssClass}
                `}
                  >
                    <img src={DeliveryIcon} alt="delivery" />
                  Delivery
                  </p>
                  <p
                    onClick={() => this.chooseDeliveryType(false)}
                    id="pickupWidget"
                    className={`${!delivery ? styles.chosenType : disableCssClass}
                  flex flex-row justify-center items-center
                  md:w-[183px] w-full px-4 py-1 gap-2 h-11 bg-transparent border
                  border-borderLight border-solid rounded-[54px]
                  text-dark font-filsonProBold cursor-pointer
                  ${hoverCssClass}
                `}
                  >
                    <img src={PickupIcon} alt="pickup" />
                  Pickup
                  </p>
                </div>
)}
            {disableCloseButton ? null : (
              <div
                className="absolute right-0 cursor-pointer w-11 h-11 hidden md:flex items-center"
                onClick={() => {
                this.handleCloseWidget(); // eslint-disable-line
              }}
              >
                <img
                  src={clearDarkIcon}
                  alt=""
                  className="w-6 h-6 object-contain"
                />
              </div>
)}
            {/* WIDGET BODY */}
            {delivery ? (
              <this.renderDeliverySection />
            ) : (
              <this.renderPickUpSection />
            )}

            <p className="w-full py-2 text-center text-darkElevationPrimary border-t-[1px] border-darkElevationPrimary">
              {!delivery
                ? "Fresh, warm cookies ready for pickup"
                : "Fresh, warm cookies delivered right to your door"}
            </p>
            {!delivery && (
            <div className="mx-auto md:hidden text-center relative">
              <div
                className=" flex items-center gap-2 cursor-pointer
                w-[190px] h-8 text-white
              bg-dark rounded-[40px] px-4 py-3
              text-sm leading-4 font-bold font-filsonProRegular zIndex
              "
                onClick={this.toLocationsPage}
              >
                <div className="flex gap-2 items-center mx-auto">
                  <img
                    className={`${styles.pickupLocationIcon}`}
                    alt="location-icon"
                    src={MapLightIcon}
                  />

                  <p>View on map</p>
                </div>
              </div>
            </div>
)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <this.deliveryPickUpWidgetContainer />;
  }
}

DeliveryPickUpWidget.propTypes = {
  isModal: PropTypes.bool,
  isShippingActive: PropTypes.bool,
  productId: PropTypes.any, // eslint-disable-line
  cmsContent: PropTypes.shape(),
  disableCloseButton: PropTypes.bool,
  showPickUpButton: PropTypes.bool,
  inputValue: PropTypes.string,
  clickPickUpButton: PropTypes.func,
  selectedPopupStore: PropTypes.shape(),
};

DeliveryPickUpWidget.defaultProps = {
  isModal: false,
  isShippingActive: true,
  productId: null,
  cmsContent: [],
  disableCloseButton: false,
  showPickUpButton: false,
  inputValue: '',
  clickPickUpButton: false,
  selectedPopupStore: {}
};

export const mapStateToProps = state => {
  const {
    userToken,
    userAddress,
    userCartId,
    userOrderMethod,
    userCart,
    selectedStore
  } = state.user;
  const { useRecaptcha } = state.elements;
  const { isShippingActive } = state.shipping;
  const {
    selectedCountry,
    allCountries,
    isCountrySelectActive
  } = state.country;
  const { useFullyQualifiedAddressForDelivery } = state.address;
  const {redeemables} = state.loyalty;
  return {
    userToken,
    userAddress,
    userCartId,
    userOrderMethod,
    userCart,
    selectedStore,
    isShippingActive,
    selectedCountry,
    allCountries,
    isCountrySelectActive,
    useFullyQualifiedAddressForDelivery,
    redirectUrl: state.address.redirectUrl,
    redeemables,
    useRecaptcha
  };
};

export const mapDispatchToProps = dispatch => ({
  setUserCart: value => dispatch(userActions.setUserCart(value)),
  setUserCartId: value => dispatch(userActions.setUserCartId(value)),
  setModalObject: value => dispatch(elementsActions.setModalObject(value)),
  setUserOrderMethod: value => dispatch(userActions.setUserOrderMethod(value)),
  setUserAddress: value => dispatch(userActions.setUserAddress(value)),
  setSelectedStore: value => dispatch(userActions.setSelectedStore(value)),
  saveAddress: value => dispatch(userActions.saveAddress(value)),
  addressUpdated: value => dispatch(addressActions.addressUpdated(value)),
  setAutocompleteSessionToken: value =>
    dispatch(elementsActions.setAutocompleteSessionToken(value)),
  setOrderMinAmount: value =>
    dispatch(elementsActions.setOrderMinAmount(value)),
  setSelectedCountry: value =>
    dispatch(countryActions.setSelectedCountry(value)),
  hideHeaderWidget: () => dispatch(elementsActions.hideHeaderWidget()),
  setVerificationToken: value =>
    dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value =>
    dispatch(elementsActions.setVerificationAction(value)),
  setVerificationProvider: value =>
    dispatch(elementsActions.setVerificationProvider(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryPickUpWidget)
);
