import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./CreateAccount.module.css";
import * as userActions from "../../../redux/actions/user";
import * as elementsActions from "../../../redux/actions/elements";
import * as userRepo from "../../../shared/repos/graphql/user";
import * as phoneService from "../../../shared/utilities/phone";

// COMPONENTS
import Button from "../../../components/Button/Button";
import CheckBox from "../../../components/CheckBox/CheckBox";
import PhoneWidget from "../../../components/PhoneWidget/PhoneWidget";
import OtpInput from "../Login/OtpInput";
import ResendOtp from "../../../components/ResendOtp/ResendOtp";
import LinkWithCountry from "../../../components/LinkWithCountry/LinkWithCountry";
import { isRecaptcha } from "../../../shared/utilities/config";

// HELPERS
import * as cms from "../../../shared/repos/cms/cms";
import cmsKeys from "../../../shared/constants/cms";
import subscription from "../../../shared/constants/subscription";
import { isValid } from "../../../shared/utilities/validations";
import { getCustomerSubscriptionsByType } from "../../../shared/utilities/subscription";
import { setCaptchaToken } from "../../../shared/utilities/captcha";
import captchaAction from "../../../shared/constants/captchaAction";
import endpoints from "../../../shared/constants/endpoints";
import { getKeyValue } from "../../../shared/utilities/cms";

class CreateAccountAfterOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props?.order?.email || "",
      firstName: props?.order?.firstName || "",
      lastName: props?.order?.lastName || "",
      telephone: props?.order?.phone || null,
      phoneNumber: null,
      otp: "",
      phoneOTPErrMessage: null,
      customerSubscriptions: [],
      subscriptionRequestCompleted: false,
      continueWithOtp: false,
      token: null,
      user: null,
      loading: false,
      responseErrorMessage: null,
      customerSubscriptionsError: [],
    };

    this.recaptchaComponentRef = React.createRef();
  }

  componentDidMount() {
    cms.getContent([cmsKeys.registrationPage], this);
    const { consent } = subscription.subscriptionType;

    getCustomerSubscriptionsByType([consent]).then(sub => {
      const sortedSubscriptions = sub ? [...sub].sort((a, b) => b.isRequired - a.isRequired) : [];
      this.setState({
        customerSubscriptions: sortedSubscriptions,
        subscriptionRequestCompleted: true,
      });
    });
  }

  handleRegister = async () => {
    if (isRecaptcha()) {
      const { setVerificationToken, setVerificationAction } = this.props;
      await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.signup);
    }
    this.handleSubmit();
  };

  onCompleteOtp = otp => {
    this.setState({ otp });
  };

  handleResendOtp = async () => {
    const { user } = this.state;
    await userRepo.resendCustomerVerificationTelephoneOtp(null, null, null, null, user?.id);
  };

  loginWithOtp = async () => {
    const { otp, user, content } = this.state;
    const { order, redirect, setUserToken } = this.props;

    try {
      this.setState({ loading: true });

      const result = await userRepo.confirmCustomerSignup(otp, user.id, order?.code);
      const { data } = result;

      if (data?.confirmCustomerSignup?.customer?.telephoneVerified) {
        this.setState({
          user: data?.confirmCustomerSignup?.customer,
          token: data?.confirmCustomerSignup?.token,
        });
        await setUserToken(data?.confirmCustomerSignup?.token);
        redirect();
      }

      this.setState({ loading: false, continueWithOtp: false });
    } catch (e) {
      const errorMessage = e.message || "Something went wrong! Please try again.";
      const errMsg = getKeyValue(content, errorMessage);
      this.setState({ phoneOTPErrMessage: errMsg || errorMessage, loading: false });
    }
  };

  handleSubmit = () => {
    const { email, firstName, lastName, phoneNumber, customerSubscriptions, content } = this.state;
    const subscriptions = customerSubscriptions.filter(item => item.defaultValue);
    const subscriptionIds = subscriptions.map(item => parseInt(item.subscriptionId, 10));

    if (!email) return this.setState({ responseErrorMessage: "Please enter an email address." });
    if (!firstName) return this.setState({ responseErrorMessage: "Please enter a first name" });
    if (!lastName) return this.setState({ responseErrorMessage: "Please enter a last name" });
    if (!phoneNumber) return this.setState({ responseErrorMessage: "Please enter a phone number" });

    if (isValid(email, "email")) {
      this.setState({ loading: true });
      userRepo
        .createAccountAndLoginV2(email, firstName, lastName, phoneNumber.code, phoneNumber.phone, null, false, subscriptionIds)
        .then(response => {
          const { token, customer } = response.data.signupCustomerV2;
          this.setState({ continueWithOtp: true, token, user: { ...customer, telephoneVerified: true }, loading: false });
          return true;
        })
        .catch(error => {
          const errMsg = getKeyValue(content, error.message);
          this.setState({ responseErrorMessage: errMsg || error.message, loading: false });
          return false;
        });
    } else {
      this.setState({ responseErrorMessage: "Invalid email address" });
      return false;
    }
    return true;
  };

  handleInput = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  validateSubscriptions = () => {
    const { customerSubscriptions } = this.state;
    const customerSubscriptionsError = customerSubscriptions.map(item => !item.defaultValue && item.isRequired);
    this.setState({ customerSubscriptionsError });
    return customerSubscriptionsError.every(error => !error);
  };

  toggleCheckbox = index => {
    const { customerSubscriptions } = this.state;
    customerSubscriptions[index].defaultValue = !customerSubscriptions[index].defaultValue;
    this.setState({
      customerSubscriptions,
      subscriptionRequestCompleted: this.validateSubscriptions(),
    });
  };

  continueWithOtp = () => {
    const { phoneNumber, otp, phoneOTPErrMessage, loading } = this.state;
    const {redirect} = this.props;
    const isDisabled = otp.length < 4;

    return (
      <div className="flex gap-3 flex-col w-full">
        <OtpInput onComplete={this.onCompleteOtp} length={4} classInput="bg-[#2C0C37] border-2 text-center text-4xl" label={phoneNumber ? `Enter the 4-digit code sent to ${phoneNumber.code} ${phoneNumber.phoneFormat}` : null} />
        {phoneOTPErrMessage && <p className={styles.hasError}>{phoneOTPErrMessage}</p>}
        <ResendOtp handleClick={this.handleResendOtp} />
        <Button isLoading={loading} disabled={isDisabled} customContainerStyles={`${styles.buttonContainer} ${isDisabled ? "!bg-disabled" : ""} w-full bg-button text-white text-center py-2 h-11`} handleSubmit={!isDisabled ? this.loginWithOtp : null} label="Continue" />
        <Button customContainerStyles={`${styles.buttonContainer} !bg-disabled !block w-full bg-button text-white text-center py-2 h-11 mt-4`} handleSubmit={redirect} label="Skip to Order Confirmation" />
      </div>
    );
  };

  render() {
    const {
      phoneNumberErrorMessage,
      subscriptionRequestCompleted,
      continueWithOtp,
      token,
      responseErrorMessage,
      loading,
      phoneNumber,
      telephone,
      customerSubscriptionsError,
      customerSubscriptions,
      firstName,
      lastName,
      email
    } = this.state;
    const {order} = this.props;
    const code = phoneService.detectCountryCode(telephone ?? "");
    const phone = phoneService.detectPhone(telephone ?? "");
    const historyUrl = endpoints.checkoutConfirmationPage.replace(":id", order?.code);
    const isDisabled = !(phoneNumber && firstName && lastName && email && subscriptionRequestCompleted);

    return (
      <div className={`${styles.pageWrapper} flex text-dark dark:text-white w-[343px] mx-auto mb-10`}>
        {!token || continueWithOtp ? (
          <div className={`${styles.pageContainer} w-[343px] flex justify-center items-center`}>
            {!continueWithOtp ? (
              <div className="flex flex-col gap-2 text-white">
                <h1 className="font-filsonProRegular text-base leading-5 !text-white mb-3">To earn points for this order, verify your phone number to create an account.</h1>
                <PhoneWidget onInput={this.handleInput} fieldName="phoneNumber" errorMessage={phoneNumberErrorMessage} code={code} phone={phone} />
                {customerSubscriptions.map((item, index) => (
                  <div key={item.id} className={styles.registerCheckbox}>
                    <CheckBox
                      onClick={() => this.toggleCheckbox(index)}
                      isChecked={item.defaultValue}
                      label={<div className='text-white' dangerouslySetInnerHTML={{ __html: item.description }} />}
                      name={`subscription-${item.subscriptionId}`}
                    />
                    {customerSubscriptionsError[index] && (
                      <p className={styles.hasError}>
                        {' '}
You must agree to `
                        {item.name}
                        {' '}

                      </p>
                    )}
                  </div>
                ))}
                {responseErrorMessage && <p className={styles.hasError}>{responseErrorMessage}</p>}

                <div className={styles.actionsContainer}>
                    <Button
                      isLoading={loading}
                      disabled={isDisabled}
                      handleSubmit={
                        isDisabled ? null : this.handleRegister
                      }
                      customContainerStyles={`
                        ${styles.buttonContainer}
                        ${isDisabled ? "!bg-disabled" : ""}
                        !block w-full bg-button text-white text-center py-2 h-11
                      `}
                      label="Send Code"
                    />
                  </div>
                <LinkWithCountry to={historyUrl} className="text-center">No Thanks</LinkWithCountry>
              </div>
            ) : (
              <div className="my-10">{this.continueWithOtp()}</div>
            )}
          </div>
        ) : ""}
      </div>
    );
  }
}

CreateAccountAfterOrder.propTypes = {
  setUserToken: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  order: PropTypes.shape({
    code: PropTypes.string
  }).isRequired,
  setVerificationAction: PropTypes.func.isRequired,
  setVerificationToken: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  content: state.cms,
});

export const mapDispatchToProps = dispatch => ({
  setUserToken: token => dispatch(userActions.setUserToken(token)),
  setVerificationToken: value =>
    dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value =>
    dispatch(elementsActions.setVerificationAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountAfterOrder);
