/* eslint-disable react/forbid-prop-types */
/* eslint-disable prefer-destructuring */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./OrderDetailsWidget.module.css";
import { getOrderTypeString } from "../../shared/utilities/orders";
import CarSVG from './imgs/car.svg';
import HouseSVG from './imgs/house.svg';
import SpoonSVG from './imgs/forkknife.svg';
import DriverSVG from './imgs/driver.svg';
import { orderDetailsWidgetModes, orderTypeStrings } from "../../shared/constants/order";
import OrderDetailsDelivery from "./OrderDetailsDelivery";
import OrderDetailsPickup from "./OrderDetailsPickup";
import Switch from '../Switch/Switch';
import { updateOrderDetails } from "../../shared/repos/graphql/order";

class OrderDetailsWidget extends Component {

  constructor(props) {
    super(props);

    const { order } = props;
    this.state = {
      orderTypeString: getOrderTypeString(order),
      mode: orderDetailsWidgetModes.view,
      isDataChanged: false,
      isFormPending: false,
      editOrderData: {
        includeSpoon: false,
        deliveryTime: null,
        telephone: null,
        comment: null,
        shippingAddress2: null,
      },
      editOrderErrors: {
        TELEPHONE_VALIDATION_ERROR: null,
        DELIVERY_TIME_ERROR: null,
        COMMON_VALIDATION_ERROR: null,
      }
    };
  }

  componentDidMount() {
    this.setState({
      editOrderData: this.resetEditOrderData(),
      editOrderErrors: this.resetEditOrderErrors(),
    });
  }

  resetEditOrderData = () => {
    const { order } = this.props;
    const resetedData = {
      includeSpoon: !!order.includeSpoon,
      deliveryTime: order.deliveryTime,
      telephone: order.recipient.phone,
      comment: order.comment,
      shippingAddress2: order.shippingAddress2,
    };
    return resetedData;
  }

  resetEditOrderErrors = () => {
    return {
      TELEPHONE_VALIDATION_ERROR: null,
      DELIVERY_TIME_ERROR: null,
      COMMON_VALIDATION_ERROR: null,
    };
  }

  onChageSpoonNeeded = () => {
    const { editOrderData } = this.state;
    let comment = editOrderData.comment;
    if (editOrderData.includeSpoon && comment) {
      comment = comment.replace('Message: . Add Spoon.', '').replace('. Add Spoon.', '');
    }
    this.onChageOrderData({
      includeSpoon: !editOrderData.includeSpoon,
      comment,
    });
  }

  handleEditOrder = async () => {
    const { order, onOrderUpdated } = this.props;
    const { editOrderData, isFormPending, mode } = this.state;
    let errorsData = this.resetEditOrderErrors();
    if (isFormPending === false) {
      this.setState({
        isFormPending: true,
        editOrderErrors: errorsData,
      });
      let modeAfterUpdate = mode;
      try {
        const orderResponce = await updateOrderDetails(order.code, editOrderData);
        if (orderResponce?.data?.updateOrder) {
          onOrderUpdated(orderResponce.data.updateOrder);
          modeAfterUpdate = orderDetailsWidgetModes.view;
        }
      } catch(e) {
        const {graphQLErrors} = e;
        if (graphQLErrors) {
          errorsData = this.handleValiationErrors(graphQLErrors);
        }
      }
      this.setState({
        isFormPending: false,
        editOrderErrors: errorsData,
        editOrderData: mode !== modeAfterUpdate
          ? this.resetEditOrderData()
          : editOrderData,
        mode: modeAfterUpdate,
      });
    }
  }

  handleValiationErrors = (errors) => {
    const editOrderErrors = this.resetEditOrderErrors();
    const reason = errors[0]?.extensions?.reason;
    if (reason && Object.keys(editOrderErrors).includes(reason)) {
      editOrderErrors[reason] = errors[0].message;
    } else {
      editOrderErrors.COMMON_VALIDATION_ERROR = errors[0].message;
    }

    return editOrderErrors;
  }

  getSpoonText = () => {
    const { editOrderData } = this.state;
    return editOrderData.includeSpoon ? 'Spoon needed' : 'Spoon not needed';
  }

  toggleMode = () => {
    const { mode } = this.state;
    this.setState({
      editOrderData: this.resetEditOrderData(),
      editOrderErrors: this.resetEditOrderErrors(),
      mode: mode === orderDetailsWidgetModes.view
        ? orderDetailsWidgetModes.edit
        : orderDetailsWidgetModes.view,
    });
  }

  onChageOrderData = (data) => {
    const { editOrderData } = this.state;

    const totalData = {
      ...editOrderData,
      ...data,
    };
    const dataChaged = this.checkDataChaged(totalData);
    this.setState({
      editOrderData: totalData,
      isDataChanged: dataChaged,
    });
  }

  checkDataChaged = (data) => {
    const { order } = this.props;
    return data.includeSpoon !== !!order.includeSpoon
      || data.deliveryTime !== order.deliveryTime
      || data.telephone !== order.recipient.phone
      || data.shippingAddress2 !== order.shippingAddress2
      || data.comment !== order.comment;
  }

  renderOrderDetails = () => {
    const { order } = this.props;
    const { orderTypeString, mode, editOrderData, editOrderErrors } = this.state;
    return orderTypeString === orderTypeStrings.delivery
      ? <OrderDetailsDelivery mode={mode} order={order} editOrderErrors={editOrderErrors} editOrderData={editOrderData} onChange={this.onChageOrderData} />
      : <OrderDetailsPickup mode={mode} order={order} editOrderErrors={editOrderErrors} editOrderData={editOrderData} onChange={this.onChageOrderData} />;
  }

  renderEditButton = () => {
    const { order } = this.props;
    const { mode } = this.state;
    return order?.status?.status <= 3
      ? (
        <button
          className={`${styles.orderDetailsEditButton} h-11 border-dark rounded-[40px] bg-white dark:bg-button text-dark`}
          onClick={() => this.toggleMode()}
          type="button"
        >
          { mode === orderDetailsWidgetModes.view ? 'Edit' : 'Reset' }
        </button>
      )
      : null;
  }

  renderTitlePickup = () => {
    return (
      <div className={[styles.orderDetailsLine, styles.orderDetailsLineTitle, styles.borderBottom].join(' ')}>
        <div className={styles.iconCell}>
          <img className="dark:image-negative" src={CarSVG} alt="car" />
        </div>
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Pickup Details</div>
          <div className={styles.orderDetailsSubtitle}>Change your pickup details until your order is boxed.</div>
        </div>
        <div className={styles.buttonCell}>
          { this.renderEditButton() }
        </div>
      </div>
    );
  }

  renderTitleDelivery = () => {
    return (
      <div className={[styles.orderDetailsLine, styles.orderDetailsLineTitle, styles.borderBottom].join(' ')}>
        <div className={styles.iconCell}>
          <img className="dark:image-negative" src={HouseSVG} alt="house" />
        </div>
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Delivery Details</div>
          <div className={styles.orderDetailsSubtitle}>Change your delivery details until your order is boxed.</div>
        </div>
        <div className={styles.buttonCell}>
          { this.renderEditButton() }
        </div>
      </div>
    );
  }

  renderTitleLine = () => {
    const { orderTypeString } = this.state;

    return orderTypeString === orderTypeStrings.delivery
      ? this.renderTitleDelivery()
      : this.renderTitlePickup();
  }

  renderSpoonLine = () => {
    const { editOrderData, mode } = this.state;
    return (
      <div className={[styles.orderDetailsLine, styles.borderTop].join(' ')}>
        <div className={styles.iconCell}>
          <img className="dark:image-negative" src={SpoonSVG} alt="spoon" />
        </div>
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Spoon</div>
          <div className={styles.orderDetailsSubtitle}>{ this.getSpoonText() }</div>
        </div>
        <div className={styles.buttonCell}>
          <Switch
            disabled={mode === orderDetailsWidgetModes.view}
            on={editOrderData.includeSpoon}
            onClick={() => this.onChageSpoonNeeded()}
          />
        </div>
      </div>
    );
  }

  renderSaveButton = () => {
    const { mode, isDataChanged, isFormPending, editOrderErrors } = this.state;
    const buttonText = isFormPending ? 'Saving...' : 'Save';
    return mode === orderDetailsWidgetModes.edit
      ? (
        <div className="text-center">
          <button
            disabled={!isDataChanged || isFormPending}
            className={`${styles.orderDetailsSaveButton} h-11 rounded-[40px] bg-dark dark:bg-button text-white font-filsonProBold`}
            onClick={() => this.handleEditOrder()}
            type="button"
          >
            { buttonText }
          </button>
          { editOrderErrors.COMMON_VALIDATION_ERROR ? (<div className={`${styles.formError} text-center`}>{ editOrderErrors.COMMON_VALIDATION_ERROR }</div>) : null }
        </div>
      )
      : null;
  }

  renderDriverSection = () => {
    const {order} = this.props;
    const { orderTypeString } = this.state;
    const { deliveryStatus } = order;
    const isBlockAvailable = orderTypeString === orderTypeStrings.delivery
      && !!deliveryStatus?.driverName
      && order?.status?.status > 3;
    return isBlockAvailable ? (
      <div className={[styles.orderDetailsLine, styles.orderDetailsLineTitle, styles.borderBottom].join(' ')}>
        <div className={styles.iconCell}>
          <img className="dark:image-negative" src={DriverSVG} alt="driver" />
        </div>
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Your Driver</div>
          <div className={styles.orderDetailsSubtitle}>{ deliveryStatus.driverName }</div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    ) : null;
  }

  render() {
    return (
      <div className={`${styles.orderDetailsContainer}`}>
        { this.renderDriverSection() }
        { this.renderTitleLine() }
        { this.renderOrderDetails() }
        { this.renderSpoonLine() }
        { this.renderSaveButton() }
      </div>
    );
  }
}

OrderDetailsWidget.propTypes = {
  order: PropTypes.object.isRequired,
  onOrderUpdated: PropTypes.func,
};

OrderDetailsWidget.defaultProps = {
  onOrderUpdated: () => {},
};

export default OrderDetailsWidget;
