import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader/Loader';
import OrderTrackingWidget from "../../components/OrderTrackingWidget/OrderTrackingWidget";

import styles from './OrderTracker.module.css';
import * as orderRepo from '../../shared/repos/graphql/order';

class OrderTracker extends Component {
  constructor(props) {
    super(props);

    const trackingID = props.match.params.id;

    this.state = {
      loading: false,
      trackingID
    };
  }

  componentDidMount() {
    const {trackingID} = this.state;

    this.state = {
      loading: false
    };

    this.getOrder(trackingID);
  }

  async getOrder(trackingID) {
    this.setState({
      loading:true
    });

    const result = await orderRepo.getTracker(trackingID);
    const { orderTracker } = result.data;

    this.setState({
      loading: false,
      order: orderTracker
    });
  }

  render() {
    const { loading, order } = this.state;

    return (
      <div className={styles.pageWrapper}>
        {loading || !order ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : (
          <div className={styles.orderTrackerWrapper}>
            <h1>Order #{order.id}</h1>

            <OrderTrackingWidget order={order} />
          </div>
        )}
      </div>
    );
  }
}

OrderTracker.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default OrderTracker;
