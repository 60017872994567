/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import _ from 'underscore';
import { connect } from 'react-redux';
import styles from '../../pages/Product/CustomizeProduct/CustomizeProduct.module.css';
import CustomProductOption from "../CustomProductOption/CustomProductOption";

/* CONSTANTS */
import { defaultCurrency } from '../../shared/constants/currency';
import { operation } from '../../shared/utilities/common';

class ProductOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customizeProducts: props.customizeProducts ?? {},
            isCustomize: false
        };
        this.productOptionElement = React.createRef();
        props.setElement(this.productOptionElement);
        this.handleChange = this.handleChange.bind(this);
    }

    /* eslint-disable camelcase */
    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            customizeProducts: props.customizeProducts
        });
    }

    renderCustomizeHeader = (option, actionLabel, action, buttonLabel, buttonAction) => {
        const { header, maxOptions, minOptions, groupTitle} = option;
        const maxQuantity = maxOptions && (maxOptions > 0) ? maxOptions : null;
        const minQuantity = minOptions && (minOptions > 0) ? minOptions : null;
        const num = minQuantity || maxQuantity;

        let title;
        let groupDesc;
        if(!num){
          title = header;
        } else {
          const str = num > 1 ? `${header}s`: header;
          title = minQuantity ? `Select  (${minQuantity}) ${ str}` : `Choose up to (${maxQuantity}) ${ str}` ;
        }
        if (groupTitle){
            groupDesc = minQuantity ? `Select  (${minQuantity}) ${ groupTitle}` : `Choose up to (${maxQuantity}) ${ groupTitle}`
        }

        return (
          <div className={styles.customizeSectionHeader}>
            <div>
              {
                <p className=' font-congenialBlack text-dark dark:text-white leading-7 tracking-[-0.2px]'>{groupDesc ?? title}</p>
              }
              {' '}
              {actionLabel ? <span onClick={action}>{actionLabel}</span> : ''}
            </div>

            {buttonLabel ? (
              <button
                onClick={buttonAction}
                type="button"
                className={styles.headerButton}
              >
                Pick for me
              </button>
            ) : null}
          </div>
        );
    };

    /* eslint-disable no-unused-expressions */

    selectLargeOnChange = (option, largeOption) => {
        const { customizeProducts } = this.state;

        const optionRemoved = customizeProducts[option.id] && customizeProducts[option.id].length > 0 && customizeProducts[option.id].includes(largeOption.id);
        const optionAdded = !customizeProducts[option.id] || customizeProducts[option.id].length < option.minOptions;
        const optionChanged = customizeProducts[option.id] && !customizeProducts[option.id].includes(largeOption.id);

        if (optionRemoved) {
            customizeProducts[option.id].pop();
        } else if (optionAdded) {
            !customizeProducts[option.id]? customizeProducts[option.id] = [largeOption.id]:
                customizeProducts[option.id].push(largeOption.id);
        } else if (optionChanged) {
            customizeProducts[option.id].pop();

            customizeProducts[option.id] = [largeOption.id];
        }
        /* eslint-disable no-unused-expressions */
        this.handleChange(customizeProducts);
    }

    /* eslint-disable no-unused-expressions */

    primaryOnChangeAdd = (option, primaryOption) => {
        const { customizeProducts } = this.state;
        let optionAdded = false;

        this.setState({
          isCustomize: true
        })

        if(option.minOptions){
          optionAdded = !customizeProducts[option.id] || customizeProducts[option.id].length < option.minOptions
        }

        if(option.maxOptions){
          optionAdded = !customizeProducts[option.id] || customizeProducts[option.id].length < option.maxOptions
        }

        if (optionAdded) {
            !customizeProducts[option.id]? customizeProducts[option.id] = [primaryOption.id]:
                customizeProducts[option.id].push(primaryOption.id);
        }
        

        /* eslint-disable no-unused-expressions */
        this.handleChange(customizeProducts);
    }

    /* eslint-disable no-unused-expressions */
    primaryOnChangeRemove = (option, primaryOption) => {
        const { customizeProducts } = this.state;

        this.setState({
          isCustomize: true
        })

        const optionRemoved = customizeProducts[option.id] && customizeProducts[option.id].length > 0;

        if (optionRemoved) {
            const indexToRemove = customizeProducts[option.id].findIndex((el) => el === primaryOption.id);

            if (indexToRemove !== -1) {
              customizeProducts[option.id].splice(indexToRemove, 1);

              if (customizeProducts[option.id].length <= 0){
                delete customizeProducts[option.id];
              }
            }
            /* eslint-disable no-unused-expressions */
            this.handleChange(customizeProducts);
        }

    }

    selectSmallOnChange = (option, smallOption) => {
        const { customizeProducts } = this.state;

        const optionRemoved = customizeProducts[option.id] && customizeProducts[option.id].includes(smallOption.id);
        const optionAdded = !customizeProducts[option.id] || customizeProducts[option.id].length < option.minOptions;
        const optionChanged = customizeProducts[option.id] && customizeProducts[option.id].length < option.maxOptions;

        if (optionRemoved) {
            customizeProducts[option.id].splice(customizeProducts[option.id].findIndex((el) => el === smallOption.id), 1);
        } else if (optionAdded) {
            !customizeProducts[option.id]? customizeProducts[option.id] = [smallOption.id]:
            customizeProducts[option.id].push(smallOption.id);
        } else if (optionChanged) {
            customizeProducts[option.id].push(smallOption.id);
        } else {
            customizeProducts[option.id].shift();

            customizeProducts[option.id].push(smallOption.id);
        }
        /* eslint-disable no-unused-expressions */
        this.handleChange(customizeProducts);
    }

    sizeSelectOnClick = (option, size) => {
        const { customizeProducts } = this.state;

        if (
            customizeProducts[option.id] &&
            customizeProducts[option.id].length > 0 &&
            customizeProducts[option.id].includes(size.id)
        ) {
            customizeProducts[option.id].pop();
        } else if (
            !customizeProducts[option.id] ||
            customizeProducts[option.id].length <
            option.minOptions
        ) {
            customizeProducts[option.id] = [size.id];
        }
        this.handleChange(customizeProducts);
    }

    isSelected = (option, sizeOption) => {
        const { customizeProducts } = this.state;

        return customizeProducts ? customizeProducts[option.id] && customizeProducts[option.id].includes(sizeOption.id) : false;
    }

    handleChange(customizeProducts) {
        const { onOptionChange } = this.props;

        this.setState({
            customizeProducts
        });

        onOptionChange(customizeProducts);
    }

    transformIceCreamOptionGroup() {
        const { options } = this.props;
        const ICE_CREAM_FLAVOR = 'Ice Cream Flavor';

        const copyOptions = options.map(
            item => (
                {...item, optionGroupType:item.header === ICE_CREAM_FLAVOR ? 'SELECT_SMALL':item.optionGroupType})
        );
        return copyOptions;
    };

    render() {
        const { currency } = this.props;
        const { customizeProducts, isCustomize } = this.state;
        const { isCaloriesActive } = this.props;
        const options = this.transformIceCreamOptionGroup();

        return (
          <div ref={this.productOptionElement} className={styles.customizeSectionContainer}>
            {options.filter(item => item.options.length > 0 ).map((option) => {
                const { maxOptions, minOptions, optionGroupType } = option;
                const groupType = optionGroupType.toLowerCase();

                const ids = option.options.map(p => p.id);
                const hasCustomizeOption = customizeProducts && customizeProducts[option.id] && !isCustomize;
                if (hasCustomizeOption) {
                  const intersect = operation(customizeProducts[option.id], ids, true)
                  const remainingProduct = minOptions - intersect.length;
                  if (remainingProduct > 0) {
                    // eslint-disable-next-line 
                    for (let i = 0; i < remainingProduct; i++) {
                      customizeProducts[option.id].push(intersect[0]);
                    }
                  }
                }

                return (
                  <div
                    key={option.header}
                    className={`productOptions--${groupType} mt-8`}
                    data-min={minOptions}
                    data-max={maxOptions}
                  >
                    {this.renderCustomizeHeader(option)}
                    {/* eslint-disable no-nested-ternary */}
                    {option.optionGroupType === "PRIMARY" ? (
                      <div
                        className={`${styles.customizeSectionRow} grid grid-flow-row-dense lg:grid-cols-3 md:grid-cols-4 gap-5 md:gap-0`}
                      >
                        {option.options.map(primaryOption => {
                          const displayQuantity = customizeProducts
                            ? _.countBy(customizeProducts[option.id])
                            : 0;
                          
                          const calories =
                            isCaloriesActive && primaryOption.calories != null
                              ? `${primaryOption.calories} kCal`
                              : ``;
                          return (
                            <CustomProductOption
                              key={primaryOption.title}
                              image={primaryOption.image}
                              name={primaryOption.title}
                              onChangeAdd={() =>
                                this.primaryOnChangeAdd(option, primaryOption)}
                              onChangeRemove={() =>
                                this.primaryOnChangeRemove(
                                  option,
                                  primaryOption
                                )}
                              quantity={displayQuantity[primaryOption.id]}
                              type="quantity"
                              price={primaryOption.price}
                              calories={calories}
                              currency={currency}
                              description={primaryOption.shortDescription}
                              oos={primaryOption.oos}
                            />
                          );
                        })}
                      </div>
                    ) : /* eslint-disable no-nested-ternary */
                    option.optionGroupType === "SELECT_SMALL" ? (
                      <div
                        id={styles.customizeSelector}
                        className={`${styles.customizeSectionRowSelector} md:grid grid-cols-1 gap-6`}
                      >
                        {option.options.map(smallOption => {
                          const calories =
                            isCaloriesActive && smallOption.calories != null
                              ? `${smallOption.calories} kCal`
                              : ``;
                          return (
                            <CustomProductOption
                              key={smallOption.title}
                              isSelected={this.isSelected(option, smallOption)}
                              onChange={() =>
                                this.selectSmallOnChange(option, smallOption)}
                              name={smallOption.title}
                              type="selector"
                              price={smallOption.price}
                              optionsCount={option.options.length}
                              calories={calories}
                              currency={currency}
                              description={smallOption.shortDescription}
                              oos={smallOption.oos}
                            />
                          );
                        })}
                      </div>
                    ) : /* eslint-disable no-nested-ternary */
                    option.optionGroupType === "SIZE_SELECT" ? (
                      <div className={styles.listOptionWrapper}>
                        {option.options.map(size => {
                          return (
                            <div
                              key={size.title}
                              onClick={() =>
                                this.sizeSelectOnClick(option, size)}
                              className={[
                                styles.listOptionContainer,
                                this.isSelected(option, size)
                                  ? styles.listOptionSelected
                                  : undefined
                              ].join(" ")}
                            >
                              <p>{size.title}</p>
                            </div>
                          );
                        })}
                      </div>
                    ) : option.optionGroupType === "SELECT_LARGE" ? (
                      <div
                        className={`${styles.customizeSectionRow} grid grid-flow-row-dense lg:grid-cols-3 md:grid-cols-4`}
                      >
                        {option.options.map(largeOption => {
                          const calories =
                            isCaloriesActive && largeOption.calories != null
                              ? `${largeOption.calories} kCal`
                              : ``;
                          return (
                            <CustomProductOption
                              key={largeOption.title}
                              onChange={() =>
                                this.selectLargeOnChange(option, largeOption)}
                              isSelected={this.isSelected(option, largeOption)}
                              image={largeOption.image}
                              name={largeOption.title}
                              type="single"
                              price={largeOption.price}
                              calories={calories}
                              currency={currency}
                              description={largeOption.shortDescription}
                              oos={largeOption.oos}
                            />
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
            })}
          </div>
        );
    }
}

ProductOptions.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ]),
        optionGroupType: PropTypes.string.isRequired
    })).isRequired,
    onOptionChange: PropTypes.func.isRequired,
    setElement: PropTypes.func,
    customizeProducts: PropTypes.objectOf(PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ]).isRequired
      )),
    isCaloriesActive: PropTypes.bool,
    currency: PropTypes.objectOf(PropTypes.string),
};

ProductOptions.defaultProps = {
  customizeProducts: null,
  setElement () {

  },
  isCaloriesActive: false,
  currency: defaultCurrency,
};

export default withRouter(connect()(ProductOptions));
